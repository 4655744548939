export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  RADIO = 'radio',
  DATE = 'date',
  TIME = 'time'
}

export enum ButtonType {
  PRIMARY,
  SECONDARY
}

export enum SnackbarType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum AppRoute {
  ROOT = '/',
  TEST = 'testing',
  LOGIN = '/belepes',
  LOGIN_MFA = '/belepes/token',
  FORGOT_PASSWORD = '/elfelejtett-jelszo',
  RESET_PASSWORD = '/jelszo-helyreallitas/:passwordResetToken',
  PROFILE = '/profil/info',
  CHANGE_PASSWORD = '/profil/jelszo-modositas',
  USER_NOTIFICATIONS = '/profil/uzenetek',
  ADMIN_AREAS = '/admin/teruletek',
  ADMIN_PROPERTY_GROUPS = '/admin/teruletek/:areaId',
  ADMIN_OFFICES = '/admin/irodak',
  ADMIN_USERS = '/admin/felhasznalok',
  ADMIN_DOCUMENT_CATEGORIES = '/admin/dokumentumtar',
  ADMIN_DOCUMENTS = '/admin/dokumentumtar/:categoryId',
  ADMIN_CALENDAR_CATEGORIES = '/admin/naptar',
  ADMIN_CALENDAR_CATEGORY_EVENTS = '/admin/naptar/:id',
  MEZZA_INCOMING = '/mezza/fogadott',
  MEZZA_OUTGOING = '/mezza/kuldott',
  MEZZA_CLOSED = '/mezza/lezart',
  MEZZA_DETAILS = '/mezza/info/:id',
  MEZZA_LOG = '/admin/mezza-log',
  MEZZA_RULES = '/mezza/szabalyzat',
  ADMIN_MEZZA_DETAILS = '/admin/mezza/:id',
  DOCUMENT_CATEGORIES = '/dokumentumtar',
  DOCUMENTS = '/dokumentumtar/:categoryId',
  CALENDAR = '/naptar'
}

export enum API {
  LOGIN_WITH_CREDENTIALS = '/login-with-credentials',
  LOGIN_WITH_TOKEN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  CHANGE_PASSWORD = '/change-password',
  REFRESH_TOKEN = '/refresh-token',
  LOGOUT = '/logout',
  OFFICES = '/offices',
  AREAS = '/areas',
  PROPERTY_GROUPS = '/property-groups',
  USERS = '/users',
  GOOGLE_MAPS_GEOCODE = 'https://maps.googleapis.com/maps/api/geocode/json?',
  DOCUMENT_CATEGORIES = '/document-categories',
  DOCUMENTS = '/documents',
  DOWNLOAD = '/download',
  MAP_DATA_VERSION = '/map-data-version',
  MAP_DATA = '/map-data',
  MEZZA = '/mezza',
  OFFICE_USERS = '/office-users',
  MEZZA_LOG = '/mezza-log',
  MEZZA_NOTIFICATION = '/mezza-notification',
  USER_NOTIFICATIONS = '/user-notifications',
  REGULAR_OFFICES = '/regular-offices',
  HIGHLIGHTED_OFFICES = '/highlighted-offices',
  CALENDAR_CATEGORIES = '/calendar-categories',
  CALENDAR_EVENTS = '/calendar-events'
}

export enum AreaStatus {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
  OPTION = 'OPTION',
  ADDITIONAL_AREA = 'ADDITIONAL_AREA'
}

export enum SortOption {
  DESC = 'desc',
  ASC = 'asc',
  NONE = 'none'
}

export enum TextAlignOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum UserRole {
  ADMIN = 'ADMIN',
  OFFICE_MANAGER = 'OFFICE_MANAGER',
  AREA_MANAGER = 'AREA_MANAGER',
  USER = 'USER'
}

export enum PropertyCategory {
  SALE = 'SALE',
  RENT = 'RENT',
  MANAGEMENT = 'MANAGEMENT'
}

export enum PropertyType {
  FLAT = 'FLAT',
  HOUSE = 'HOUSE',
  SITE = 'SITE', // telek
  GARAGE = 'GARAGE',
  HOLIDAY = 'HOLIDAY', // nyaraló
  OFFICE = 'OFFICE',
  BUSINESS = 'BUSINESS', // üzlethelyiség
  CATERING = 'CATERING', //  VENDÉGLÁTÓIPARI EGYSÉG
  INDUSTRIAL = 'INDUSTRIAL',
  AGRICULTURAL = 'AGRICULTURAL',
  DEVELOPMENT = 'DEVELOPMENT'
}

export enum FlatSubType {
  BRICK = 'BRICK',
  PANEL = 'PANEL',
  SLIDING_SHUTTER = 'SLIDING_SHUTTER'
}

export enum HouseSubType {
  FAMILY = 'FAMILY',
  TWIN = 'TWIN',
  LINE = 'LINE',
  PARTIAL = 'PARTIAL',
  CASTLE = 'CASTLE',
  FARM = 'FARM',
  LIGHT_STRUCTURE = 'LIGHT_STRUCTURE'
}

export enum SiteSubType {
  RESIDENTIAL_ZONE = 'RESIDENTIAL_ZONE',
  RESORT_AREA = 'RESORT_AREA',
  FOREIGN_TERRITORY = 'FOREIGN_TERRITORY',
  OTHER_PLOT = 'OTHER_PLOT'
}

export enum GarageSubType {
  SINGLE = 'SINGLE',
  ROOM = 'ROOM'
}

export enum HolidaySubType {
  WEEKEND_HOME = 'WEEKEND_HOME',
  HOLIDAY_HOUSE = 'HOLIDAY_HOUSE'
}

export enum OfficeSubType {
  OFFICE_BUILDING_A = 'OFFICE_BUILDING_A',
  OFFICE_BUILDING_B = 'OFFICE_BUILDING_B',
  CATEGORY_C = 'CATEGORY_C',
  FAMILY_HOUSE = 'FAMILY_HOUSE',
  FLAT = 'FLAT',
  OTHER_OFFICE = 'OTHER_OFFICE'
}

export enum BusinessSubType {
  IN_A_STORE = 'IN_A_STORE',
  WITH_BUSINESS_ENTRANCE = 'WITH_BUSINESS_ENTRANCE',
  IN_THE_YARD = 'IN_THE_YARD',
  OTHER_BUSINESS_PREMISES = 'OTHER_BUSINESS_PREMISES'
}

export enum CateringSubType {
  HOTEL = 'HOTEL',
  RESTAURANT = 'RESTAURANT',
  OTHER = 'OTHER'
}

export enum IndustrialSubType {
  STORAGE = 'STORAGE',
  WORKSHOP = 'WORKSHOP',
  SITE = 'SITE',
  PLOT_FOR_INDUSTRIAL_USE = 'PLOT_FOR_INDUSTRIAL_USE',
  OTHER = 'OTHER'
}

export enum AgriculturalSubType {
  GENERAL = 'GENERAL'
}

export enum DevelopmentSubType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  MIXED = 'MIXED',
  INDUSTRIAL = 'INDUSTRIAL',
  RESORT = 'RESORT',
  SPECIAL = 'SPECIAL'
}

export enum MezzaGroup {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  CLOSED = 'CLOSED'
}

export enum MezzaState {
  NOT_TAKEN_OVER = 'NOT_TAKEN_OVER',
  TAKEN_OVER = 'TAKEN_OVER',
  EXPIRED = 'EXPIRED',
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  ACQ_WRITTEN = 'ACQ_WRITTEN',
  ACQ_NOT_WRITTEN = 'ACQ_NOT_WRITTEN',
  NO_ACQ = 'NO_ACQ',
  DONE = 'DONE'
}

export enum MezzaActivity {
  SEND = 'SEND',
  ASSIGN = 'ASSIGN',
  STATE_UPDATE = 'STATE_UPDATE',
  MESSAGE = 'MESSAGE',
  DATA_UPDATE = 'DATA_UPDATE'
}
