import { useEffect, useState } from 'react';

import { MezzaFormValues } from './MezzaForm';
import { MapData } from '../../types/types';

type AddressValidationProps = MezzaFormValues & {
  settlements: MapData['settlements'];
  counties: MapData['counties'];
};

const AddressValidation = ({
  settlements,
  counties,
  zip,
  settlement,
  street,
  streetType,
  county
}: AddressValidationProps) => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setShowWarning(false);

    if ([zip, settlement, street, streetType, county].every(Boolean)) {
      const locality = settlements.find(({ id }) => String(id) === settlement)?.name ?? '';
      const countyName = counties.find(({ id }) => String(id) === county)?.name ?? '';

      validateAddress(zip, locality, [`${street} ${streetType}`, countyName, 'Magyarország']).then(
        (res) => {
          setShowWarning(res);
        }
      );
    }
  }, [zip, settlement, street, streetType, county, counties, settlements]);

  if (showWarning) {
    return (
      <div className="mb-25" style={{ color: '#d1192e', fontSize: '1.25rem' }}>
        A megadott cím pontatlan lehet, vagy nem létezik, biztos, hogy elmented?
      </div>
    );
  }

  return <div>Biztosan el akarja küldeni a mezzát?</div>;
};

const validateAddress = async (zip: string, locality: string, addressLines: string[]) => {
  try {
    const response = await fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
      {
        method: 'POST',
        body: JSON.stringify({
          address: {
            regionCode: 'HU',
            postalCode: zip,
            locality,
            addressLines
          }
        })
      }
    );
    const { result } = await response.json();

    if (
      result?.verdict?.hasUnconfirmedComponents ||
      result?.address?.addressComponents?.some(({ spellCorrected }: { spellCorrected?: boolean }) =>
        Boolean(spellCorrected)
      )
    ) {
      return true;
    }

    return false;
  } catch (e) {
    console.error(e);

    return true;
  }
};

export default AddressValidation;
