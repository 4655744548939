import CustomInput from '../Input/CustomInput';

import styles from './ColorInput.module.scss';

interface ColorInputProps {
  onChange: (val: string) => void;
  color?: string;
  error?: string;
}

const ColorInput = ({ color, onChange, error }: ColorInputProps) => {
  return (
    <CustomInput
      error={error}
      label={
        <label className={styles.label}>
          Szín
          {color && (
            <div
              className={styles.selectedColor}
              style={{
                backgroundColor: color
              }}
            />
          )}
        </label>
      }>
      <div className={styles.options}>
        {AVAILABLE_COLORS.map((c) => (
          <div
            key={c}
            onClick={() => onChange(c)}
            style={{
              backgroundColor: c,
              border: color === c ? '4px solid #71f247' : '2px solid #707070'
            }}
          />
        ))}
      </div>
    </CustomInput>
  );
};

const AVAILABLE_COLORS = [
  '#ffffff',
  '#030305',
  '#e1262a',
  '#f1e232',
  '#6bb139',
  '#72c6d8',
  '#234e99',
  '#a2579a',
  '#be2b33',
  '#e02732',
  '#e65c31',
  '#f09232',
  '#f6af4a',
  '#f4e647',
  '#d8d841',
  '#c7b19c',
  '#988576',
  '#736358',
  '#524843',
  '#c59c6f',
  '#a47c55',
  '#8b623e',
  '#744d28',
  '#5d3817',
  '#402315',
  '#9a9a9a',
  '#9cc0f9',
  '#f3c300',
  '#2b519b',
  '#d1192e',
  '#90be4d',
  '#4fac51',
  '#209048',
  '#186739',
  '#3fac74',
  '#27aa9d',
  '#2bace0',
  '#1373b6',
  '#2c3989',
  '#27295a',
  '#623687',
  '#8a3486',
  '#9a1c60',
  '#ce1e5d',
  '#e3287a'
];

export default ColorInput;
