import useHasSideBar from '../../hooks/useHasSideBar';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
  bgSrc?: string;
}

const Layout = ({ children, bgSrc }: LayoutProps) => {
  const hasPageSidebar = useHasSideBar();

  return (
    <div
      className={`${styles.layout} ${hasPageSidebar ? styles.wide : ''}`}
      {...(bgSrc ? { style: { backgroundImage: `url(${bgSrc})` } } : {})}
    >
      {children}
    </div>
  );
};

export default Layout;
