import { useState } from 'react';

import Cards from '../components/Cards/Cards';
import Form from '../components/Form/Form';
import Layout from '../components/Layout/Layout';
import Map from '../components/Map/Map';
import Popup from '../components/Popup/Popup';
import PaginatedTable from '../components/Table/PaginatedTable';
import TableControllers from '../components/Table/TableControllers';
import InfoBlock from '../components/InfoBlock/InfoBlock';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../data/constants';
import { buttonLabels, helperLabels, inputLabels, titles } from '../data/labels';
import { OFFICES_TABLE_COLUMNS_CONFIG } from '../data/tableDefs';
import useData from '../hooks/useData';
import useFilter from '../hooks/useFilter';
import { fetchAreas, fetchCalendarEvents, fetchRegularOffices } from '../requests';
import { ButtonType } from '../types/enums';
import { CalendarEvent, FormRowBody, Office } from '../types/types';
import Calendar from '../components/Calendar/Calendar';
import { getEventLegend } from '../utils/common';
import CalendarEventDetails from '../components/CalendarEventDetails/CalendarEventDetails';

const Root = () => {
  const [offices] = useData(fetchRegularOffices);
  const [events] = useData(fetchCalendarEvents);
  const [areas] = useData(
    fetchAreas,
    'Hiba történt a Területek letöltése során, kérjük próbálja később.'
  );
  const [query, setQuery] = useState('');
  const filteredOffices = useFilter(offices, query);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [popupData, setPopupData] = useState<Office>();
  const [eventInDetails, setEventInDetails] = useState<CalendarEvent>();

  return (
    <div>
      <Map areas={areas} offices={offices} />
      <Layout>
        <Calendar events={events} legend={getEventLegend(events)} showDetails={setEventInDetails} />
        <h1>{titles.offices}</h1>
        <InfoBlock />
        <Cards />
        <TableControllers
          searchTitle={helperLabels.searchInOffices}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredOffices}
          columns={OFFICES_TABLE_COLUMNS_CONFIG}
          actions={[
            {
              title: buttonLabels.view,
              minWidth: 140,
              width: 10,
              actionHandler: setPopupData
            }
          ]}
          defaultSortColumn="areaCode"
        />
      </Layout>
      {popupData && (
        <Popup
          show={!!popupData}
          title={titles.officeDetails}
          onHide={() => setPopupData(undefined)}
          footerButtons={[
            {
              title: buttonLabels.close,
              type: ButtonType.PRIMARY,
              action: () => setPopupData(undefined)
            }
          ]}
        >
          <Form data={popupData} rows={OFFICE_DETAILS_FORM_ROWS} readonly />
        </Popup>
      )}
      {eventInDetails && (
        <CalendarEventDetails
          onClose={() => setEventInDetails(undefined)}
          event={eventInDetails}
          category={eventInDetails.calendar_category}
        />
      )}
    </div>
  );
};

const OFFICE_DETAILS_FORM_ROWS: { [key: string]: FormRowBody }[] = [
  { areaCode: { header: inputLabels.areaCode } },
  { name: { header: inputLabels.officeName } },
  { address: { header: inputLabels.address } },
  { managerName: { header: inputLabels.managerName } },
  { managerPhone: { header: inputLabels.phone } },
  { managerEmail: { header: inputLabels.email } },
  { office_phone: { header: inputLabels.officePhone } },
  { office_email: { header: inputLabels.officeEmail } }
];

export default Root;
