import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';
import { isValidPhoneNumber } from '../../../utils/common';

const ClientPhone = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.CLIENT_PHONE}
      rules={rules}
      render={({ field }) => (
        <Input
          id={MezzaFormField.CLIENT_PHONE}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.clientPhone}
          error={
            formState.errors[MezzaFormField.CLIENT_PHONE]?.message
              ? `${formState.errors[MezzaFormField.CLIENT_PHONE]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

const rules = {
  validate: {
    phone: (v: string) => !!isValidPhoneNumber(v) || 'A telefonszám formátuma nem megfelelő.'
  },
  required: 'Kötelező mező!'
};

export default React.memo(ClientPhone);
