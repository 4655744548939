import { useEffect, useMemo, useState } from 'react';

import Table, { TableProps } from './Table';
import TableHeader from './TableHeader';
import { SortOption } from '../../types/enums';
import Pagination from '../Pagination/Pagination';
import { stringComparator } from '../../utils/common';
import { Comparator } from '../../types/types';

import styles from './PaginatedTable.module.scss';

interface PaginatedTableProps<T> extends Omit<TableProps<T>, 'gridColumnStyle'> {
  rowsPerPage: number;
  data: T[];
  defaultSortColumn: keyof T & string;
  defaultSortOrder?: SortOption;
  footerValues?: (string | null)[]; // null values needed for column spacing
}

function PaginatedTable<T>({
  rowsPerPage,
  data,
  columns,
  actions,
  defaultSortColumn,
  defaultSortOrder = SortOption.ASC,
  footerValues = []
}: PaginatedTableProps<T>) {
  const [sortedColumn, setSortedColumn] = useState<{
    fieldName: keyof T & string;
    sortOrder: SortOption;
    comparator: Comparator;
  }>({
    fieldName: defaultSortColumn,
    sortOrder: defaultSortOrder,
    comparator:
      columns.find(({ field }) => field === defaultSortColumn)?.comparator ?? stringComparator
  });
  const [currentPage, setCurrentPage] = useState(0);

  const gridColumnStyle = calculateGridStyle([
    ...columns.map(extractWidth),
    actions.reduce((sum, action) => sum + action.minWidth, 0) + 'px'
  ]);

  const visibleData = useMemo(
    () =>
      sortDataByField(data, sortedColumn).slice(
        Math.max(currentPage * rowsPerPage, 0),
        (currentPage + 1) * rowsPerPage
      ),
    [currentPage, rowsPerPage, data, sortedColumn]
  );

  const pageCount = useMemo(() => Math.ceil(data.length / rowsPerPage), [data, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [data, rowsPerPage]);

  return (
    <div className={styles.wrapper}>
      <TableHeader
        columns={columns}
        gridColumnStyle={gridColumnStyle}
        sortedColumn={sortedColumn}
        onSortChange={setSortedColumn}
      />
      <Table
        columns={columns}
        gridColumnStyle={gridColumnStyle}
        actions={actions}
        data={visibleData}
      />
      {footerValues && (
        <div className={styles.footerValues} style={gridColumnStyle}>
          {footerValues.map((val, idx) => (
            <div
              key={idx}
              style={{
                minWidth: columns[idx].minWidth / window.devicePixelRatio + 'px'
              }}
            >
              {val}
            </div>
          ))}
        </div>
      )}
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
    </div>
  );
}

const calculateGridStyle = (widths: string[]) => {
  return {
    display: 'grid',
    gridTemplateColumns: widths.map((it) => it ?? 'auto').join(' ')
  };
};

const extractWidth = (data: { width: string | number }) =>
  data.width === 'auto' ? 'auto' : data.width + '%';

function sortDataByField<T>(
  data: T[],
  {
    fieldName,
    sortOrder,
    comparator
  }: {
    fieldName: keyof T;
    sortOrder: SortOption;
    comparator: Comparator;
  }
) {
  if (sortOrder === SortOption.NONE) return data;

  const sorted = data.sort(comparator(fieldName));

  if (sortOrder === SortOption.DESC) {
    sorted.reverse();
  }

  return sorted;
}

export default PaginatedTable;
