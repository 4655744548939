import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button/Button';
import DeleteForm from '../../components/DeleteForm/DeleteForm';
import Layout from '../../components/Layout/Layout';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import PaginatedTable from '../../components/Table/PaginatedTable';
import TableControllers from '../../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import { titles, buttonLabels, helperLabels } from '../../data/labels';
import { CALENDAR_EVENT_TABLE_COLUMN_CONFIG } from '../../data/tableDefs';
import useFilter from '../../hooks/useFilter';
import { deleteCalendarEvent, fetchCalendarCategoryEvents } from '../../requests';
import { AppRoute, ButtonType, SnackbarType } from '../../types/enums';
import { CalendarCategory, CalendarEvent } from '../../types/types';
import CalendarEventForm from '../../components/CalendarEventForm/CalendarEventForm';
import { NotificationContext } from '../../App';
import { formatEventDateTime, getMonthFirstDay, getMonthLastDay } from '../../utils/common';
import CalendarEventDetails from '../../components/CalendarEventDetails/CalendarEventDetails';
import CalendarEventFilterBlock from '../../components/CalendarEventFilterBlock/CalendarEventFilterBlock';

const CalendarCategoryEvents = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const notify = useContext(NotificationContext);
  const resetFilters = useRef<() => {}>();
  const reApplyAdvancedFilters = useRef<() => CalendarEvent[]>();

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [category, setCategory] = useState<CalendarCategory>();
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredEvents = useFilter(events, query);
  const [eventInEdit, setEventInEdit] = useState<CalendarEvent>();
  const [eventInDetails, setEventInDetails] = useState<CalendarEvent>();
  const [eventToDelete, setEventToDelete] = useState<CalendarEvent>();
  const [showEventEditor, setShowEventEditor] = useState(false);
  const [filteredByAdvancedFilters, setFilteredByAdvancedFilters] = useState(filteredEvents);
  const [calendarLimits, setCalendarLimits] = useState({
    start: getMonthFirstDay(),
    end: getMonthLastDay()
  });

  useEffect(() => {
    setFilteredByAdvancedFilters(filteredEvents);
    reApplyAdvancedFilters.current?.();
  }, [filteredEvents]);

  const refresh = useCallback(() => {
    fetchCalendarCategoryEvents(+id)
      .then((res) => {
        setEvents(res.data.events);
        setCategory(res.data.category);
      })
      .catch((e) => {
        notify('Hiba történt, kérjük próbálja később.', SnackbarType.ERROR);
        console.error(e);
      });
  }, [fetchCalendarCategoryEvents, notify]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setShowEventEditor(false);
      setEventInEdit(undefined);

      shouldReload && refresh();
    },
    [refresh]
  );

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>Események/{category?.name}</h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}>
          <p className="mb-25">
            Ebben a menüpontban lehetősége van a naptár kategórián belüli elemeinek kiválasztására
            és szerkesztésére.
          </p>
          <div style={{ marginBottom: '-50px' }}>
            <Button
              type={ButtonType.SECONDARY}
              inverse
              action={() => navigate(AppRoute.ADMIN_CALENDAR_CATEGORIES)}>
              Vissza
            </Button>
          </div>
        </div>
        <TableControllers
          searchTitle={helperLabels.searchInCalendarEvents}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
          resetFilters={resetFilters.current}
          renderFilters={() => (
            <CalendarEventFilterBlock
              events={filteredEvents}
              onChange={setFilteredByAdvancedFilters}
              resetFilters={resetFilters}
              calendarLimits={calendarLimits}
              reApplyAdvancedFilters={reApplyAdvancedFilters}
            />
          )}>
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowEventEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newCalendarEvent}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredByAdvancedFilters}
          columns={CALENDAR_EVENT_TABLE_COLUMN_CONFIG}
          actions={[
            {
              title: buttonLabels.view,
              minWidth: 110,
              width: 9,
              actionHandler: setEventInDetails
            },
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 6,
              actionHandler: setEventToDelete
            },
            {
              title: buttonLabels.edit,
              minWidth: 110,
              width: 9,
              actionHandler: (category) => {
                setEventInEdit(category);
                setShowEventEditor(true);
              }
            }
          ]}
          defaultSortColumn="start_date"
        />
      </Layout>
      {showEventEditor && <CalendarEventForm onExit={onCloseEditForm} initialEvent={eventInEdit} />}
      {eventInDetails && (
        <CalendarEventDetails
          onClose={() => setEventInDetails(undefined)}
          event={eventInDetails}
          category={category as CalendarCategory}
          showUserLevel
        />
      )}
      {eventToDelete && (
        <DeleteForm
          onExit={() => setEventToDelete(undefined)}
          title={titles.deleteUser}
          subtitle={`Biztosan törölni kívánja a következő eseményt: ${eventToDelete.name} ?`}
          onDelete={() => deleteCalendarEvent(eventToDelete.id).then(refresh)}
        />
      )}
    </>
  );
};

export const renderEventTime = (_val: string | number, event: CalendarEvent) => (
  <div>{formatEventDateTime(event)}</div>
);

export default CalendarCategoryEvents;
