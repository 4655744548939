import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';
import { MezzaFormFieldProps, MezzaFormField } from '../MezzaForm';
import { MapData } from '../../../types/types';
import { constructMapDataOptions } from '../../../utils/common';

interface SettlementProps extends MezzaFormFieldProps {
  settlements: MapData['settlements'];
}

const MAP_DATA_ID_BP = 3;

const Settlement = ({ control, settlements, resetField, formState }: SettlementProps) => {
  const county = useWatch({ control, name: MezzaFormField.COUNTY });
  const disabled = !county;

  useEffect(() => {
    if (formState.dirtyFields[MezzaFormField.COUNTY]) {
      resetField(MezzaFormField.SETTLEMENT, { keepError: true });
    }
  }, [county]);

  const options = useMemo(() => {
    const filtered = settlements.filter(({ pid }) => String(pid) === String(county));

    if (String(county) === String(MAP_DATA_ID_BP)) {
      return constructMapDataOptions(filtered).sort(sortRomanNumbers);
    }

    return constructMapDataOptions(filtered);
  }, [settlements, county]);

  return (
    <Controller
      control={control}
      name={MezzaFormField.SETTLEMENT}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.SETTLEMENT}
          value={
            (
              options.find(({ key }) => key === field.value) ?? {
                value: 'Kérjük válasszon!'
              }
            ).value
          }
          setValue={field.onChange}
          label={inputLabels.settlements}
          options={[{ key: '', value: 'Kérjük válasszon!' }, ...options]}
          withSearch
          disabled={disabled}
          error={
            formState.errors[MezzaFormField.SETTLEMENT]?.message
              ? `${formState.errors[MezzaFormField.SETTLEMENT]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

const romanNumeralsForBpDistricts = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'XIV',
  'XV',
  'XVI',
  'XVII',
  'XVIII',
  'XIX',
  'XX',
  'XXI',
  'XXII',
  'XXIII'
];

const sortRomanNumbers = (a: { value: string }, b: { value: string }) => {
  const first = a.value.split('.')[0];
  const second = b.value.split('.')[0];

  return romanNumeralsForBpDistricts.indexOf(first) - romanNumeralsForBpDistricts.indexOf(second);
};

export default React.memo(Settlement);
