import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../components/Button/Button';
import DeleteForm from '../../components/DeleteForm/DeleteForm';
import Layout from '../../components/Layout/Layout';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import PaginatedTable from '../../components/Table/PaginatedTable';
import TableControllers from '../../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import { titles, buttonLabels, helperLabels } from '../../data/labels';
import { CALENDAR_CATEGORIES_TABLE_COLUMN_CONFIG } from '../../data/tableDefs';
import useData from '../../hooks/useData';
import useFilter from '../../hooks/useFilter';
import { fetchCalendarCategories, deleteCalendarCategory } from '../../requests';
import { AppRoute, ButtonType } from '../../types/enums';
import { CalendarCategory } from '../../types/types';
import CalendarCategoryForm from '../../components/CalendarCategoryForm/CalendarCategoryForm';
import { generatePath, useNavigate } from 'react-router-dom';
import CalendarCategoryFilterBlock from '../../components/CalendarCategoryFilterBlock/CalendarCategoryFilterBlock';

const CalendarCategories = () => {
  const navigate = useNavigate();
  const [categories, refreshCategories] = useData(
    fetchCalendarCategories,
    'Hiba történt az Naptár Kategóriák letöltése során, kérjük próbálja később.'
  );
  const reApplyAdvancedFilters = useRef<() => CalendarCategory[]>();
  const resetFilters = useRef<() => {}>();
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredCategories = useFilter(categories, query);
  const [categoryInEdit, setCategoryInEdit] = useState<CalendarCategory>();
  const [categoryToDelete, setCategoryToDelete] = useState<CalendarCategory>();
  const [showCategoryEditor, setShowCategoryEditor] = useState(false);
  const [filteredByAdvancedFilters, setFilteredByAdvancedFilters] = useState(filteredCategories);

  useEffect(() => {
    setFilteredByAdvancedFilters(filteredCategories);
    reApplyAdvancedFilters.current?.();
  }, [filteredCategories]);

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setShowCategoryEditor(false);
      setCategoryInEdit(undefined);

      shouldReload && refreshCategories();
    },
    [refreshCategories]
  );

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.manageCalendarCategories}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a naptár kategóriák kiválasztására és szerkesztésére.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInCalendarCategories}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
          resetFilters={resetFilters.current}
          renderFilters={() => (
            <CalendarCategoryFilterBlock
              categories={filteredCategories}
              onChange={setFilteredByAdvancedFilters}
              resetFilters={resetFilters}
              reApplyAdvancedFilters={reApplyAdvancedFilters}
            />
          )}
        >
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowCategoryEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newCalendarCategory}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredByAdvancedFilters}
          columns={CALENDAR_CATEGORIES_TABLE_COLUMN_CONFIG}
          actions={[
            {
              title: buttonLabels.more,
              minWidth: 65,
              width: 6,
              actionHandler: ({ id }) => {
                navigate(
                  generatePath(AppRoute.ADMIN_CALENDAR_CATEGORY_EVENTS, {
                    id: id.toString()
                  })
                );
              }
            },
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 6,
              actionHandler: setCategoryToDelete
            },
            {
              title: buttonLabels.edit,
              minWidth: 130,
              width: 9,
              actionHandler: (category) => {
                setCategoryInEdit(category);
                setShowCategoryEditor(true);
              }
            }
          ]}
          defaultSortColumn="name"
        />
      </Layout>
      {showCategoryEditor && (
        <CalendarCategoryForm onExit={onCloseEditForm} initialCategory={categoryInEdit} />
      )}
      {categoryToDelete && (
        <DeleteForm
          onExit={() => setCategoryToDelete(undefined)}
          title={titles.deleteUser}
          subtitle={`Biztosan törölni kívánja a következő kategóriát: ${categoryToDelete.name} ?`}
          onDelete={() => deleteCalendarCategory(categoryToDelete.id).then(refreshCategories)}
        />
      )}
    </>
  );
};

export const renderWithColorStripe = (val: string | number, category: CalendarCategory) => (
  <div style={{ display: 'flex', height: '100%' }}>
    <div
      style={{
        backgroundColor: category.color,
        width: 10,
        margin: '-12px 12px -12px -12px',
        flexShrink: 0
      }}
    ></div>
    <strong>{val}</strong>
  </div>
);

export default CalendarCategories;
