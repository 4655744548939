import styles from './RadioInputOptions.module.scss';
import { InputType } from '../../types/enums';

interface RadioInputOptionProps {
  options: { key: string; value: string }[];
  value: string;
  setValue: (val: string) => void;
  id?: string;
}

const RadioInputOptions = ({
  options,
  value = options[0].key,
  id = 'radio',
  setValue
}: RadioInputOptionProps) => {
  return (
    <div className={styles.container}>
      {options.map((option) => (
        <div className={styles.option} key={option.key} onClick={() => setValue(option.key)}>
          <input
            type={InputType.RADIO}
            className={styles.option__input}
            name={id}
            value={option.key}
            id={option.key}
            checked={option.value == value ? true : false}
            onChange={() => setValue(option.key)}
          />
          <span className={styles.option__checkmark} />
          <label htmlFor={option.key} className={styles.option__label}>
            {option.value}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioInputOptions;
