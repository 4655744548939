import { ReactElement } from 'react';

import styles from './Input.module.scss';

interface CustomInputProps extends React.PropsWithChildren {
  label: ReactElement;
  error?: string;
}

const CustomInput = ({ error, label, children }: CustomInputProps) => {
  return (
    <div className={error ? styles.error : ''}>
      {label}
      {children}
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export default CustomInput;
