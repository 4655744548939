import useHasSideBar from '../../hooks/useHasSideBar';

import './Footer.scss';

const Footer = () => {
  const hasPageSidebar = useHasSideBar();

  return (
    <footer {...(hasPageSidebar ? { style: { marginLeft: -300 } } : {})}>
      <div>
        <span>1068 Budapest, Király utca 102.</span>
        <span>+36 1 352 1900</span>
        <span>info@tecnocasa.hu</span>
      </div>
      <div>© 2023 TECNOCASA | MINDEN JOG FENNTARVA!</div>
    </footer>
  );
};

export default Footer;
