import { AppRoute, AreaStatus, MezzaState, UserRole } from '../types/enums';
import { MapLocation, Office, Validation, CalendarEvent, Document } from '../types/types';
import { mezzaStatusTextByState } from './mezza';

export const isAdminPage = () =>
  window.location.host.includes(process.env.REACT_APP_ADMIN_SUBDOMAIN!);

export const isActiveLink = (link: AppRoute) => window.location.pathname.includes(link);

export const isValidEmail = (email: string) => email.match(/^\S+@\S+\.\S+$/i);

export const isValidPhoneNumber = (phoneNumber: string) => phoneNumber.match(/^[0-9\s\-\/()\+]+$/i);

const AREA_STATUS_COLORS = {
  [AreaStatus.FREE]: '#1B7B2B',
  [AreaStatus.OPTION]: '#FF9400',
  [AreaStatus.OCCUPIED]: '#D1192E',
  [AreaStatus.ADDITIONAL_AREA]: '#0c6ddb'
};

export const AREA_STATUS_LABELS: Record<AreaStatus, string> = {
  [AreaStatus.FREE]: 'Szabad',
  [AreaStatus.OCCUPIED]: 'Foglalt',
  [AreaStatus.OPTION]: 'Opció alatt',
  [AreaStatus.ADDITIONAL_AREA]: 'Kiegészítő terület'
};

export const renderTextByAreaStatusColor = (status: AreaStatus = AreaStatus.FREE, text: string) => (
  <span style={{ color: AREA_STATUS_COLORS[status] }}>{text}</span>
);

export const convertPointsToLatLng = (coordinates: number[][]): MapLocation[] =>
  coordinates.map(([lat, lng]) => ({ lat, lng }));

export function filterData<T extends Record<string, any>>(data: T[], query: string) {
  const queries = query.toLowerCase().split(' ');
  const containsAllQueryTerms = (value: unknown) =>
    queries.every((queryTerm) => String(value).toLowerCase().includes(queryTerm));

  const checkObjectValuesForQuery = (it: object): boolean =>
    Object.values(it).some((value) => {
      if (typeof value === 'object' && value) {
        return checkObjectValuesForQuery(value);
      }

      return containsAllQueryTerms(value);
    });

  return data.filter(checkObjectValuesForQuery);
}

export const renderInBold = (val: string | number) => <strong>{val}</strong>;

export const USER_ROLE_LABELS = {
  [UserRole.USER]: 'Felhasználó',
  [UserRole.AREA_MANAGER]: 'Területi felelős',
  [UserRole.OFFICE_MANAGER]: 'Irodavezető',
  [UserRole.ADMIN]: 'Admin'
};

export const renderUserRight = (role: UserRole) => <span>{USER_ROLE_LABELS[role]}</span>;

export const renderCategoryName = (categoryName: string | number): JSX.Element => (
  <span>{categoryName}</span>
);

export const renderLastLogin = (last_login?: string) => (
  <span>{last_login ? last_login.split(' ')[0] : ''}</span>
);

export const renderDateTime = (date_time?: string) => (
  <span>{date_time ? date_time.split('T')[0] : ''}</span>
);

export const renderAreaStatus = (val: AreaStatus) =>
  renderTextByAreaStatusColor(val, AREA_STATUS_LABELS[val]);

export const hasMinLength = (value: string, minLength: number) => value.length >= minLength;

export const checkLength = (value: string, minLength: number, maxLength: number) =>
  value.length >= minLength && value.length <= maxLength;

export const isPositiveInteger = (value: number) => value >= 0 && Number.isInteger(value);

export const isValidFileExtension = (file: File, allowedExtensions: string[]) =>
  allowedExtensions.includes(file.name.substr(file.name.lastIndexOf('.') + 1));

export function validateItem<T>(item: T, validations: Validation<T>[]) {
  return validations.reduce((errors, { field, validate, error }) => {
    if (!validate(item[field], item)) {
      return {
        ...errors,
        [field]: error
      };
    }

    return errors;
  }, {} as { [key in T as string]: string });
}

export function replaceEmptyStringFieldValueWithNull<T extends Record<string, any>>(item: T) {
  return Object.fromEntries(
    Object.entries(item).map(([field, value]) => (value !== '' ? [field, value] : [field, null]))
  ) as T;
}

export const constructOfficeOptions = (offices: Office[]) =>
  offices
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ key: String(id), value: name }));

export const constructMapDataOptions = (data: { id: number; name: string }[]) =>
  data
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ key: String(id), value: name }));

export function areaStatusComparator<T>(fieldName: keyof T) {
  return (a: T, b: T) =>
    (AreaStatus[(a[fieldName] as keyof typeof AreaStatus) || ''] ?? '').localeCompare(
      AreaStatus[(b[fieldName] as keyof typeof AreaStatus) || ''] ?? ''
    );
}

export function stringComparator<T>(fieldName: keyof T) {
  return (a: T, b: T) =>
    String(a[fieldName as keyof T]).localeCompare(String(b[fieldName as keyof T]));
}

export function numberComparator<T>(fieldName: keyof T) {
  return (a: T, b: T) => Number(a[fieldName]) - Number(b[fieldName]);
}

export function mezzaStateComparator<T>(fieldName: keyof T) {
  return (a: T, b: T) =>
    mezzaStatusTextByState[a[fieldName] as MezzaState].localeCompare(
      mezzaStatusTextByState[b[fieldName] as MezzaState]
    );
}

export const validateTimeString = (val: string) => {
  if (val === '' || val === null) {
    return true;
  }

  const [hours, minutes] = val.split(':');

  if (!hours || !minutes || isNaN(Number(hours)) || isNaN(Number(minutes))) {
    return false;
  }

  if (+hours < 0 || +hours > 23 || +minutes < 0 || +minutes > 59) {
    return false;
  }

  return true;
};

export function eventDateTimeComparator() {
  return (a: CalendarEvent, b: CalendarEvent) => {
    const aDate = a.start_date;
    const bDate = b.start_date;

    if (aDate === bDate) {
      if (a.start === b.start) return 0;

      return (a.start ?? '').localeCompare(b.start ?? '');
    }

    return aDate.localeCompare(bDate);
  };
}

export const formatEventDateTime = ({ start_date, end_date, start, end }: CalendarEvent) => {
  if (start_date === end_date) {
    return `${new Date(start_date).toLocaleDateString()} ${
      start || end ? ` ${start ?? ''}-${end ?? ''}` : ''
    }`;
  }

  return `${new Date(start_date).toLocaleDateString()}${start ? ` ${start}` : ''} - ${new Date(
    end_date
  ).toLocaleDateString()}${end ? ` ${end}` : ''}`;
};

const viewableExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];

export const isDownloadActionVisible = (doc: Document) =>
  !viewableExtensions.includes(doc.path.split('.').pop() ?? '');

export const isViewActionVisible = (doc: Document) =>
  viewableExtensions.includes(doc.path.split('.').pop() ?? '');

export const eventCategoryComparator = () => (a: CalendarEvent, b: CalendarEvent) =>
  a.calendar_category.name.localeCompare(b.calendar_category.name);

export const getEventLegend = (events: CalendarEvent[]) =>
  events.reduce((legends, { calendar_category: { color, name } }) => {
    if (legends[color]) {
      return legends;
    }

    return { ...legends, [color]: name };
  }, {} as Record<string, string>);

export const getMonthFirstDay = () => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const firstDay = new Date(y, m, 2);

  return firstDay.toISOString().split('T')[0];
};

export const getMonthLastDay = () => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const lastDay = new Date(y, m + 1, 0);

  return lastDay.toISOString().split('T')[0];
};
