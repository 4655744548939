import moment from 'moment';

import {
  LOCAL_STORAGE_TOKEN_EXPIRE,
  LOCAL_STORAGE_TOKEN_EXPIRE_MFA,
  LOCAL_STORAGE_TOKEN_KEY,
  TOKEN_EXPIRE_MINUTES
} from '../data/constants';
import {
  axiosInstance,
  fetchFreshToken,
  fetchLoginByCredentials,
  fetchLoginByToken,
  logout
} from '../requests';
import { MezzaState, SnackbarType, UserRole } from '../types/enums';
import { Mezza, Notify, User } from '../types/types';

export const setToken = (token: string, expire: string) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRE, expire);

  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setMfaLoginExpire = (expire: string) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRE_MFA, expire);
};

export const destroyToken = () => {
  logout();
  localStorage.clear();
  axiosInstance.defaults.headers.common['Authorization'] = undefined;
};

const isLastTokenExpired = () => {
  const exp = localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRE);

  return !exp || moment(exp).isBefore(moment().subtract(1, 'minute'));
};

export const getLastTokenIfNotExpired = () => {
  return !isLastTokenExpired() && localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const isMfaTokenValid = () => {
  const mfaTokenExpire = localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRE_MFA);
  return mfaTokenExpire && moment(mfaTokenExpire).isAfter(moment().add(1, 'minute'));
};

export const loginByToken = async (lastToken: string, notify: Notify, isMfaToken = false) => {
  try {
    const {
      data: { token, expires, user }
    } = await fetchLoginByToken(lastToken, isMfaToken);

    if (!token) return false;

    setToken(token, expires.toString());
    startTokenRefreshingCountdown();

    return user;
  } catch (e) {
    console.error('Login by token failed', e);
    notify('A bejelentkezés nem sikerült, a token lejárt vagy nem található.', SnackbarType.ERROR);
    return false;
  }
};

export const refreshToken = async () => {
  try {
    const {
      data: { token, expires }
    } = await fetchFreshToken();
    setToken(token, expires);
    startTokenRefreshingCountdown();
  } catch (e) {
    console.error('Refreshing token failed', e);
  }
};

export const loginByCredentials = async (email: string, password: string, notify: Notify) => {
  try {
    const {
      data: { expires }
    } = await fetchLoginByCredentials(email, password);

    if (!expires) return false;

    setMfaLoginExpire(expires);

    return true;
  } catch (e) {
    console.error('Login by credentials failed', e);
    notify('A bejelentkezés nem sikerült.', SnackbarType.ERROR);
    return false;
  }
};

const startTokenRefreshingCountdown = () => {
  setTimeout(refreshToken, TOKEN_EXPIRE_MINUTES * 60 * 1000);
};

export const isAdmin = ({ role }: User) => role === UserRole.ADMIN;

export const canAccessAdminPage = (role: UserRole) => role === UserRole.ADMIN;

export const hasMezzaAccess = ({ role }: User) =>
  [UserRole.AREA_MANAGER, UserRole.OFFICE_MANAGER].includes(role);

export const hasMezzaCreateAccess = ({ role }: User) => UserRole.OFFICE_MANAGER === role;

export const hasMezzaStatusEditAccess = (user: User, mezza: Mezza) => {
  const isUserTheRecipient = user.id === mezza.toUser?.id;
  const isMezzaNotClosed = mezza.state !== MezzaState.CLOSED;

  if (user.role === UserRole.AREA_MANAGER) {
    return isUserTheRecipient && isMezzaNotClosed;
  }

  if (user.role === UserRole.OFFICE_MANAGER) {
    return (isUserTheRecipient || user.officeId === mezza.recipient_office?.id) && isMezzaNotClosed;
  }

  if (isAdmin(user)) {
    return true;
  }

  return false;
};

export const hasMezzaAssignAccess = (user: User, mezza: Mezza) => {
  const isMezzaNotClosed = mezza.state !== MezzaState.CLOSED;

  if (user.role === UserRole.OFFICE_MANAGER) {
    if (
      mezza.state === MezzaState.NOT_TAKEN_OVER &&
      mezza.recipient_office?.id === (user.officeId as number)
    ) {
      return false;
    }

    return (
      [mezza.recipient_office?.id, mezza.sender_office?.id].includes(user.officeId as number) &&
      isMezzaNotClosed
    );
  }

  if (isAdmin(user)) {
    return true;
  }

  return false;
};
