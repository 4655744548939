import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';
import { MezzaFormFieldProps, MezzaFormField } from '../MezzaForm';
import { MapData } from '../../../types/types';
import { constructMapDataOptions } from '../../../utils/common';

interface CountyProps extends MezzaFormFieldProps {
  counties: MapData['counties'];
}

const MAP_DATA_ID_BP = 3;
const MAP_DATA_ID_PEST = 4;

const County = ({ control, counties, formState }: CountyProps) => {
  const options = useMemo(
    () => [
      ...constructMapDataOptions(
        counties.filter(({ id }) => [MAP_DATA_ID_BP, MAP_DATA_ID_PEST].includes(id))
      ),
      ...constructMapDataOptions(
        counties.filter(({ id }) => ![MAP_DATA_ID_BP, MAP_DATA_ID_PEST].includes(id))
      )
    ],
    [counties]
  );

  return (
    <Controller
      control={control}
      name={MezzaFormField.COUNTY}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.COUNTY}
          value={
            (
              options.find(({ key }) => key === field.value) ?? {
                value: 'Kérjük válasszon!'
              }
            ).value
          }
          setValue={field.onChange}
          label={inputLabels.county}
          options={[{ key: '', value: 'Kérjük válasszon!' }, ...options]}
          withSearch
          error={
            formState.errors[MezzaFormField.COUNTY]?.message
              ? `${formState.errors[MezzaFormField.COUNTY]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(County);
