import { useCallback, useContext, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

import { NotificationContext } from '../../App';
import { titles, buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import { fetchUpdateOrCreateOffice, getLocationByAddress } from '../../requests';
import { ButtonType, SnackbarType } from '../../types/enums';
import Form from '../Form/Form';
import Popup from '../Popup/Popup';
import {
  hasMinLength,
  isValidEmail,
  isValidPhoneNumber,
  replaceEmptyStringFieldValueWithNull,
  validateItem
} from '../../utils/common';
import Input from '../Input/Input';
import { Office, Validation, FormRowBody } from '../../types/types';

interface OfficeFormProps {
  onExit: (shouldReload?: boolean) => void;
  initialOffice?: Office;
}

const OfficeForm = ({ onExit, initialOffice = EMPTY_OFFICE }: OfficeFormProps) => {
  const notify = useContext(NotificationContext);
  const [office, setOffice] = useState(initialOffice);
  const [formErrors, setFormErrors] = useState({});
  const [showLocationInput, setShowLocationInput] = useState(false);
  const [manualLocation, setManualLocation] = useState('');

  useEffect(() => {
    return () => {
      setFormErrors({});
    };
  }, []);

  const onSuccess = useCallback(() => {
    notify(
      office.id === -1 ? 'Az új iroda sikeresen létrejött.' : 'Az iroda frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [office, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      office.id === -1
        ? 'Az új iroda létehozása nem sikerült.'
        : 'Az iroda frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [office, notify]);

  const getOfficeLocation = useCallback(async () => {
    if (office.address === initialOffice.address) {
      return office.location;
    }

    const location = await getLocationByAddress(encodeURI(office.address));

    if (location) {
      setShowLocationInput(false);
      return JSON.stringify(Object.values(location));
    } else if (
      manualLocation !== '' &&
      showLocationInput &&
      manualLocation.split(',').length === 2
    ) {
      return JSON.stringify([
        Number(manualLocation.split(',')[0]),
        Number(manualLocation.split(',')[1])
      ]);
    } else {
      setShowLocationInput(true);
      return false;
    }
  }, [office.address, office.location, showLocationInput, initialOffice.address, manualLocation]);

  const onSave = useCallback(async () => {
    const errors = validateItem(office, VALIDATIONS);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0 && !isEqual(office, initialOffice)) {
      const completeOffice: Office = { ...office };

      const location = await getOfficeLocation();
      if (location) {
        completeOffice.location = location;

        fetchUpdateOrCreateOffice(
          replaceEmptyStringFieldValueWithNull(completeOffice),
          onSuccess,
          onError
        );
      }
    }
  }, [office, initialOffice, onError, onSuccess, getOfficeLocation]);

  return (
    <Popup
      show
      title={office.id === -1 ? titles.createOffice : titles.editOffice}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        },
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        }
      ]}
    >
      <Form
        data={office}
        onChange={(val) => setOffice(val as Office)}
        rows={PROPERTY_FORM_ROWS}
        errors={formErrors}
      />
      {showLocationInput && (
        <Input
          id="manualLocation"
          value={manualLocation}
          error={`Az automatikus koordináta meghatározás nem sikerült.\r\nKérjük adja meg manuálisan az iroda lokációját!\r\nPl: 47.4974743, 19.0379766`}
          label="Lokáció (lang, lat)"
          placeholder="12.345678, 47.123456"
          setValue={setManualLocation}
        />
      )}
    </Popup>
  );
};

const VALIDATIONS: Validation<Office>[] = [
  {
    field: 'name',
    validate: (val: string) => hasMinLength(val, 5),
    error: `${errors.mandatoryTextField}\r\nMinimum 5, maximum 190 karakter.`
  },
  {
    field: 'address',
    validate: (val: string) => hasMinLength(val, 5),
    error: `${errors.mandatoryTextField}\r\nMinimum 5, maximum 190 karakter.`
  },
  {
    field: 'office_email',
    validate: (val: string) => !!isValidEmail(val),
    error: errors.emailFormat
  },
  {
    field: 'office_phone',
    validate: (val: string) => !!isValidPhoneNumber(val),
    error: errors.phoneNumber
  }
];

const EMPTY_OFFICE: Office = {
  safeToDelete: true,
  id: -1,
  areaCode: '',
  name: '',
  address: '',
  office_email: '',
  office_phone: '',
  managerPhone: '',
  managerName: '',
  managerEmail: ''
};

const PROPERTY_FORM_ROWS: {
  [key: string]: FormRowBody;
}[] = [
  {
    areaCode: {
      header: inputLabels.areaCode,
      readonly: true
    }
  },
  {
    name: {
      header: inputLabels.officeName,
      placeholder: placeholders.officeName
    }
  },
  {
    address: { header: inputLabels.address, placeholder: placeholders.address }
  },
  {
    managerName: {
      header: inputLabels.managerName,
      readonly: true
    }
  },
  {
    managerPhone: {
      header: inputLabels.phone,
      readonly: true
    }
  },
  {
    managerEmail: {
      header: inputLabels.email,
      readonly: true
    }
  },
  {
    office_phone: {
      header: inputLabels.officePhone,
      placeholder: placeholders.officePhone
    }
  },
  {
    office_email: {
      header: inputLabels.officeEmail,
      placeholder: placeholders.officeEmail
    }
  }
];

export default OfficeForm;
