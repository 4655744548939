import { useState } from 'react';

import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import Layout from '../components/Layout/Layout';
import PasswordUpdateForm from '../components/PasswordUpdateForm/PasswordUpdateForm';
import ProfileSidebar from '../components/Sidebar/ProfileSidebar';
import Snackbar from '../components/Snackbar/Snackbar';
import { buttonLabels, inputLabels, placeholders, titles } from '../data/labels';
import { fetchChangePassword } from '../requests';
import { ButtonType, InputType, SnackbarType } from '../types/enums';

const ChangePassword = () => {
  const [newPasswordValidator, setNewPasswordValidator] = useState<{
    validate: () => boolean;
  }>({
    validate: () => false
  });
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [isPasswordUpdated, setIsPasswordUpdated] = useState<SnackbarType>();

  const updatePassword = () => {
    if (newPasswordValidator.validate()) {
      fetchChangePassword(password, oldPassword)
        .then(() => {
          setIsPasswordUpdated(SnackbarType.SUCCESS);
        })
        .catch(() => {
          setIsPasswordUpdated(SnackbarType.ERROR);
        });
    }
  };

  return (
    <>
      <ProfileSidebar />
      <Layout>
        <h1>{titles.changePassword}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a belépési jelszavának megváltoztatására.
        </p>
        <div style={{ maxWidth: 560 }}>
          <PasswordUpdateForm setPassword={setPassword} setValidator={setNewPasswordValidator} />
          <p>
            <strong>
              A művelettel megváltozik a jelszava. Biztosan meg kívánja változtatni a jelszavát?
            </strong>
          </p>
          <p>
            <strong>Amennyiben igen, úgy adja meg a jelenleg használt jelszavát.</strong>
          </p>
          <div className="mb-25">
            <Input
              id="old-password"
              type={InputType.PASSWORD}
              value={oldPassword}
              setValue={setOldPassword}
              label={inputLabels.password}
              placeholder={placeholders.password}
            />
          </div>
          <Button action={updatePassword} type={ButtonType.SECONDARY}>
            {buttonLabels.savePassword}
          </Button>
          {isPasswordUpdated === SnackbarType.SUCCESS && (
            <Snackbar type={isPasswordUpdated} hide={() => setIsPasswordUpdated(undefined)}>
              Gratulálunk, sikeresen megváltoztatta a jelszavát.
            </Snackbar>
          )}
          {isPasswordUpdated === SnackbarType.ERROR && (
            <Snackbar type={isPasswordUpdated} hide={() => setIsPasswordUpdated(undefined)}>
              A jelszó megváltoztatása nem sikerült.
            </Snackbar>
          )}
        </div>
      </Layout>
    </>
  );
};

export default ChangePassword;
