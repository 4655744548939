import { buttonLabels, titles } from '../../data/labels';
import { AppRoute } from '../../types/enums';
import Sidebar from './Sidebar';
import SidebarMenuItem from './SidebarMenuItem';

import { ReactComponent as Arrow } from './icons/submenu-arrow.svg';

const MezzaSidebar = () => {
  return (
    <Sidebar title={titles.mezzaSystem}>
      <>
        <SidebarMenuItem target={AppRoute.MEZZA_INCOMING} icon={Arrow}>
          {buttonLabels.mezzaIncoming}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.MEZZA_OUTGOING} icon={Arrow}>
          {buttonLabels.mezzaOutgoing}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.MEZZA_CLOSED} icon={Arrow}>
          {buttonLabels.mezzaClosed}
        </SidebarMenuItem>
        <SidebarMenuItem primary target={AppRoute.MEZZA_RULES} icon={Arrow}>
          {buttonLabels.rules}
        </SidebarMenuItem>
      </>
    </Sidebar>
  );
};

export default MezzaSidebar;
