import React from 'react';

import { MezzaActivity } from '../../types/enums';

import board from './board.png';
import envelope from './envelope.png';

import styles from './MezzaActivityCard.module.scss';

interface MezzaActivityCardProps extends React.PropsWithChildren {
  activity: MezzaActivity.MESSAGE | MezzaActivity.STATE_UPDATE;
  actor: string;
  dateTime: string;
}

const MezzaActivityCard = ({ activity, actor, dateTime, children }: MezzaActivityCardProps) => {
  return (
    <div className={activity === MezzaActivity.MESSAGE ? styles.wrapper : styles.stateWrapper}>
      <div className={styles.title}>
        <ActivityDisplay actor={actor} activity={activity} />
        {new Date(dateTime).toLocaleString()}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

const ActivityDisplay = ({
  activity,
  actor
}: Pick<MezzaActivityCardProps, 'activity' | 'actor'>) => {
  return (
    <div className={styles.activity}>
      <img src={activity === MezzaActivity.MESSAGE ? envelope : board} alt="" />
      <strong>{activity === MezzaActivity.MESSAGE ? 'Üzenet' : 'Státusz'}</strong>
      {' - '}
      {actor}
    </div>
  );
};

export default MezzaActivityCard;
