import { useCallback, useContext, useState } from 'react';

import { Mezza, User } from '../../types/types';
import { ButtonType, MezzaState, SnackbarType } from '../../types/enums';
import { mezzaStatusTextByState } from '../../utils/mezza';
import Input from '../Input/Input';
import { hasMezzaStatusEditAccess, isAdmin } from '../../utils/auth';
import { NotificationContext, UserContext } from '../../App';
import { buttonLabels } from '../../data/labels';
import Popup from '../Popup/Popup';
import TextArea from '../TextArea/TextArea';
import { updateMezzaState } from '../../requests';

import styles from './MezzaStateUpdater.module.scss';

interface MezzaStateUpdaterProps {
  mezza: Mezza;
  onChange: () => void;
  hide: () => void;
}

const initialErrors = { note: false, state: false };

const MezzaStateUpdater = ({ mezza, onChange, hide }: MezzaStateUpdaterProps) => {
  const { user } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [newState, setNewState] = useState(mezza.state);
  const [note, setNote] = useState('');
  const [errors, setErrors] = useState(initialErrors);

  const states = isAdmin(user as User) ? ALL_STATES : availableNextStates(mezza.state);
  const options = states.map((it) => ({
    key: it,
    value: mezzaStatusTextByState[it]
  }));

  const onStatusUpdate = useCallback(() => {
    const newErrors = { ...initialErrors };
    if (newState === mezza.state) {
      newErrors.state = true;
    }
    if (!note) {
      newErrors.note = true;
    }
    setErrors(newErrors);

    if (newErrors.note || newErrors.state) {
      return;
    }

    updateMezzaState(mezza.id, newState, note)
      .then(() => {
        notify('A státusz sikeresen módosítva.', SnackbarType.SUCCESS);
        onChange();
        hide();
      })
      .catch(() => {
        notify('Hiba történt a mezza státuszának módosítása során.', SnackbarType.ERROR);
      });
  }, [mezza, notify, onChange, newState, note, hide]);

  return (
    <Popup
      show
      title="Státusz módosítása"
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: hide,
          inverse: true
        },
        {
          title: buttonLabels.changeStatus,
          type: ButtonType.PRIMARY,
          action: onStatusUpdate
        }
      ]}
      onHide={hide}
    >
      <div className={styles.popup}>
        <p>Biztosan módosítani kívánja a(z) {mezza.id} mezza státuszát? </p>
        <Input
          options={options}
          setValue={(val) => setNewState(val as MezzaState)}
          value={mezzaStatusTextByState[newState]}
          id="state"
          label="Státusz"
          disabled={!hasMezzaStatusEditAccess(user as User, mezza)}
          error={errors.state ? 'A státusz nem változott!' : ''}
        />
        <TextArea
          id="note"
          label="Üzenet"
          value={note}
          setValue={setNote}
          error={errors.note ? 'Kötelező mező!' : ''}
        />
      </div>
    </Popup>
  );
};

const ALL_STATES = [
  MezzaState.NOT_TAKEN_OVER,
  MezzaState.TAKEN_OVER,
  MezzaState.IN_PROGRESS,
  MezzaState.ACQ_NOT_WRITTEN,
  MezzaState.ACQ_WRITTEN,
  MezzaState.NO_ACQ,
  MezzaState.DONE,
  MezzaState.CLOSED
];

const availableNextStates = (state: MezzaState) => {
  switch (state) {
    case MezzaState.NOT_TAKEN_OVER:
      return [MezzaState.TAKEN_OVER, MezzaState.CLOSED];
    case MezzaState.TAKEN_OVER:
      return [MezzaState.IN_PROGRESS];
    case MezzaState.IN_PROGRESS:
      return [MezzaState.ACQ_NOT_WRITTEN, MezzaState.ACQ_WRITTEN, MezzaState.NO_ACQ];
    case MezzaState.NO_ACQ:
      return [MezzaState.CLOSED];
    case MezzaState.ACQ_NOT_WRITTEN:
      return [MezzaState.ACQ_WRITTEN, MezzaState.CLOSED];
    case MezzaState.ACQ_WRITTEN:
      return [MezzaState.DONE, MezzaState.CLOSED];
    case MezzaState.DONE:
      return [MezzaState.CLOSED];
    default:
      return [];
  }
};

export default MezzaStateUpdater;
