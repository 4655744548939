import { useContext } from 'react';

import { UserContext } from '../App';
import Layout from '../components/Layout/Layout';
import ProfileSidebar from '../components/Sidebar/ProfileSidebar';
import { titles } from '../data/labels';
import { renderUserRight } from '../utils/common';
import { UserRole } from '../types/enums';

const Profile = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <ProfileSidebar />
      <Layout>
        <h1>{titles.profileData}</h1>
        <p className="mb-25">A rendszerünk Önt az alábbi regisztrációs adatokkal tartja nyilván.</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '200px auto',
            gridGap: '12px 40px'
          }}
        >
          <strong>E-mail cím:</strong>
          <span>{user?.email}</span>
          <strong>Név:</strong>
          <span>{user?.name}</span>
          <strong>Jogosultság:</strong>
          <span>{renderUserRight(user?.role ?? UserRole.USER)}</span>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
