import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';

const Zip = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.ZIP}
      rules={rules}
      render={({ field }) => (
        <Input
          id={MezzaFormField.ZIP}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.zip}
          error={
            formState.errors[MezzaFormField.ZIP]?.message
              ? `${formState.errors[MezzaFormField.ZIP]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

const rules = {
  validate: {
    fourDigits: (v: string) =>
      v === '' || (Number(v) > 999 && Number(v) < 10000) || '4 számjegyű mező, vagy üres'
  }
};

export default React.memo(Zip);
