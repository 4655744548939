import { buttonLabels, titles } from '../../data/labels';
import { AppRoute } from '../../types/enums';
import Sidebar from './Sidebar';
import SidebarMenuItem from './SidebarMenuItem';

import { ReactComponent as KeyIcon } from './icons/key.svg';
import { ReactComponent as UserIcon } from './icons/user.svg';
import { ReactComponent as MessageIcon } from './icons/envelope.svg';

const ProfileSidebar = () => {
  return (
    <Sidebar title={titles.profileData}>
      <>
        <SidebarMenuItem target={AppRoute.PROFILE} icon={UserIcon}>
          {buttonLabels.profile}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.CHANGE_PASSWORD} icon={KeyIcon}>
          {buttonLabels.editPassword}
        </SidebarMenuItem>
        <SidebarMenuItem target={AppRoute.USER_NOTIFICATIONS} icon={MessageIcon}>
          {buttonLabels.messageSetup}
        </SidebarMenuItem>
      </>
    </Sidebar>
  );
};

export default ProfileSidebar;
