import { FunctionComponent, SVGProps } from 'react';
import { NavLink } from 'react-router-dom';

import { AppRoute } from '../../types/enums';

import styles from './Sidebar.module.scss';

interface SidebarMenuItemProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  children: string;
  target: AppRoute;
  primary?: boolean;
}

const SidebarMenuItem = ({
  children,
  icon: Icon,
  target,
  primary = false
}: SidebarMenuItemProps) => {
  if (primary) {
    return (
      <NavLink className={styles.primaryNavItem} to={target}>
        <Icon />
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink className={({ isActive }) => (isActive ? styles.menuActive : undefined)} to={target}>
      <Icon />
      {children}
    </NavLink>
  );
};

export default SidebarMenuItem;
