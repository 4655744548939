import React, { useCallback, useEffect, useState } from 'react';

import { inputLabels, placeholders } from '../../data/labels';
import { InputType } from '../../types/enums';
import Input from '../Input/Input';

interface PasswordUpdateFormProps {
  setValidator: React.Dispatch<
    React.SetStateAction<{
      validate: () => boolean;
    }>
  >;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordUpdateForm = ({
  setValidator,
  setPassword: setParentPassword
}: PasswordUpdateFormProps) => {
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [inputError, setInputError] = useState('');
  const [inputAgainError, setInputAgainError] = useState('');

  const resetInputErrors = () => {
    setInputError('');
    setInputAgainError('');
  };

  const isPasswordReady = useCallback(() => {
    resetInputErrors();

    if (
      !password.match(/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?:\.]))(?=.{8,20})/) ||
      password.length < 8 ||
      password.length > 20
    ) {
      setInputError(
        'A jelszónak meg kell felelnie az alábbiaknak:\r\n Minimum 8, maximum 20 karakter\r\n Tartalmaznia kell kis- és nagybetűt\r\n Tartalmaznia kell legalább egy számot\r\n Tartalmazni kell legalább egy speciális karaktert a következők közül: !@#$%^&*?:.'
      );
      return false;
    }

    if (password !== passwordAgain) {
      setInputAgainError('A két jelszónak azonosnak kell lennie!');
      return false;
    }

    return true;
  }, [password, passwordAgain]);

  useEffect(() => {
    setValidator({
      validate: isPasswordReady
    });
  }, [password, passwordAgain]);

  useEffect(() => {
    setParentPassword(password);
  }, [password]);

  return (
    <>
      <Input
        id="password"
        type={InputType.PASSWORD}
        value={password}
        setValue={setPassword}
        label={inputLabels.newPassword}
        placeholder={placeholders.newPassword}
        error={inputError}
      />
      <Input
        id="password-confirm"
        type={InputType.PASSWORD}
        value={passwordAgain}
        setValue={setPasswordAgain}
        label={inputLabels.newPasswordAgain}
        placeholder={placeholders.newPasswordAgain}
        error={inputAgainError}
      />
    </>
  );
};

export default PasswordUpdateForm;
