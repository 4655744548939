import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';

const HouseNumber = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.HOUSE_NUMBER}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.HOUSE_NUMBER}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.houseNumber}
          error={
            formState.errors[MezzaFormField.HOUSE_NUMBER]?.message
              ? `${formState.errors[MezzaFormField.HOUSE_NUMBER]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(HouseNumber);
