import { useCallback, useContext, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

import { NotificationContext } from '../../App';
import { titles, buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import { fetchUpdateOrCreateDocumentCategory } from '../../requests';
import { ButtonType, SnackbarType } from '../../types/enums';
import Popup from '../Popup/Popup';
import {
  checkLength,
  replaceEmptyStringFieldValueWithNull,
  validateItem
} from '../../utils/common';
import { DocumentCategory, Validation } from '../../types/types';
import TextArea from '../TextArea/TextArea';
import Input from '../Input/Input';

interface DocumentCategoryFormProps {
  onExit: (shouldReload?: boolean) => void;
  initialDocumentCategory?: DocumentCategory;
}

type DocumentCategoryFormErrors = {
  name?: string;
  description?: string;
};

const DocumentCategoryForm = ({
  onExit,
  initialDocumentCategory = EMPTY_DOCUMENT_CATEGORY
}: DocumentCategoryFormProps) => {
  const notify = useContext(NotificationContext);
  const [documentCategory, setDocumentCategory] = useState(initialDocumentCategory);
  const [formErrors, setFormErrors] = useState<DocumentCategoryFormErrors>(
    {} as DocumentCategoryFormErrors
  );

  useEffect(() => {
    return () => {
      setFormErrors({} as DocumentCategoryFormErrors);
    };
  }, []);

  const onSuccess = useCallback(() => {
    notify(
      documentCategory.id === -1
        ? 'Az új dokumentum kategória sikeresen létrejött.'
        : 'A dokumentum kategória frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [documentCategory, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      documentCategory.id === -1
        ? 'Az új dokumentum kategória létehozása nem sikerült.'
        : 'A dokumentum kategória frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [documentCategory, notify]);

  const onSave = useCallback(async () => {
    const errors = validateItem(documentCategory, VALIDATIONS);
    setFormErrors(errors as DocumentCategoryFormErrors);

    if (Object.keys(errors).length === 0 && !isEqual(documentCategory, initialDocumentCategory)) {
      const completeDocumentCategory: DocumentCategory = {
        ...documentCategory
      };

      fetchUpdateOrCreateDocumentCategory(
        replaceEmptyStringFieldValueWithNull(completeDocumentCategory),
        onSuccess,
        onError
      );
    }
  }, [documentCategory, initialDocumentCategory, onError, onSuccess]);

  return (
    <Popup
      show
      title={documentCategory.id === -1 ? titles.newCategory : titles.editCategory}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        },
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        }
      ]}
    >
      <Input
        id="name"
        label={inputLabels.documentCategoryName}
        value={documentCategory.name ?? ''}
        setValue={(val) =>
          setDocumentCategory((documentCategory) => ({
            ...documentCategory,
            name: val
          }))
        }
        error={formErrors.name}
        placeholder={placeholders.documentCategoryName}
      />
      <TextArea
        id="description"
        label={inputLabels.description}
        value={documentCategory.description ?? ''}
        setValue={(val) =>
          setDocumentCategory((documentCategory) => ({
            ...documentCategory,
            description: val
          }))
        }
        placeholder={placeholders.documentCategoryDescription}
        error={formErrors.description}
      />
    </Popup>
  );
};

const VALIDATIONS: Validation<DocumentCategory>[] = [
  {
    field: 'name',
    validate: (val: string) => checkLength(val, 2, 40),
    error: `${errors.mandatoryTextField}\r\nMinimum 2, maximum 40 karakter.`
  },
  {
    field: 'description',
    validate: (val: string) => checkLength(val, 2, 80),
    error: `${errors.mandatoryTextField}\r\nMinimum 2, maximum 80 karakter.`
  }
];

const EMPTY_DOCUMENT_CATEGORY: DocumentCategory = {
  id: -1,
  name: '',
  description: ''
};

export default DocumentCategoryForm;
