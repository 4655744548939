import React, { useMemo, useState } from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker, Polygon } from '@react-google-maps/api';

import { AreaStatus } from '../../types/enums';
import {
  AREA_STATUS_LABELS,
  convertPointsToLatLng,
  renderTextByAreaStatusColor
} from '../../utils/common';
import { Area, Office, FormattedArea, MapLocation, FormattedOffice } from '../../types/types';

import marker from './marker.svg';
import group1Marker from './group1-marker.svg';
import group2Marker from './group2-marker.svg';
import group3Marker from './group3-marker.svg';

const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 47.503,
  lng: 19.067
};

export interface MapProps {
  areas: Area[];
  offices: Office[];
}

type InfoBlockData = FormattedArea & {
  isAreaClick?: boolean;
  location: MapLocation;
};

const Map = ({ areas, offices }: MapProps) => {
  const [infoBlockData, setInfoBlockData] = useState<InfoBlockData>();

  const transformedAreas: FormattedArea[] = useMemo(() => areas.map(formatArea), [areas]);

  const transformedOffices: FormattedOffice[] = useMemo(
    () =>
      offices.map((office) => ({
        ...office,
        location: convertPointsToLatLng([JSON.parse(office.location || '[]')])[0]
      })),
    [offices]
  );

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY ?? ''}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        clickableIcons={false}
        onClick={() => setInfoBlockData(undefined)}
      >
        {transformedOffices.map((office) => (
          <Marker
            key={office.id}
            icon={getMarker(office.id)}
            onClick={() =>
              setInfoBlockData({
                ...(office.area ? formatArea(office.area as Area) : ({} as FormattedArea)),
                location: office.location,
                office
              })
            }
            position={office.location!}
          />
        ))}

        {transformedAreas.map((area) => (
          <Polygon
            key={area.id}
            onClick={(e: google.maps.MapMouseEvent) =>
              setInfoBlockData({
                ...area,
                location: {
                  lat: e.latLng!.lat(),
                  lng: e.latLng!.lng()
                },
                isAreaClick: true
              })
            }
            paths={area.geojson}
            options={getAreaOptions(area.status)}
          />
        ))}

        {infoBlockData ? (
          <InfoWindow
            position={infoBlockData.location}
            onCloseClick={() => setInfoBlockData(undefined)}
            {...(infoBlockData.isAreaClick
              ? {}
              : {
                  options: {
                    pixelOffset: new google.maps.Size(0, -60, undefined, 'px')
                  }
                })}
          >
            <InfoBlock data={infoBlockData} />
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </LoadScript>
  );
};

const GROUP_1_OFFICE_IDS = process.env.REACT_APP_OFFICE_IDS_FOR_GROUP_1?.split(',') ?? [];
const GROUP_2_OFFICE_IDS = process.env.REACT_APP_OFFICE_IDS_FOR_GROUP_2?.split(',') ?? [];
const GROUP_3_OFFICE_IDS = process.env.REACT_APP_OFFICE_IDS_FOR_GROUP_3?.split(',') ?? [];

const getMarker = (id: number) => {
  switch (true) {
    case GROUP_1_OFFICE_IDS.includes(id.toString()):
      return group1Marker;
    case GROUP_2_OFFICE_IDS.includes(id.toString()):
      return group2Marker;
    case GROUP_3_OFFICE_IDS.includes(id.toString()):
      return group3Marker;
    default:
      return marker;
  }
};

const formatArea = (area: Area): FormattedArea => ({
  ...area,
  geojson: convertPointsToLatLng(JSON.parse(area.geojson)),
  office: {
    ...(area.office as Office),
    location: convertPointsToLatLng([JSON.parse(area.office?.location || '[]')])[0]
  }
});

interface InfoBlockProps {
  data: FormattedArea;
}

const InfoBlock = ({ data }: InfoBlockProps) => {
  if (data.office && data.status !== AreaStatus.FREE) {
    return (
      <div style={{ fontSize: 16 }}>
        <div>
          <strong>Terület: </strong>
          <span>
            {renderTextByAreaStatusColor(
              data.status,
              AREA_STATUS_LABELS[data.status as AreaStatus]
            )}
          </span>
        </div>
        <div>
          <strong>Kezelt ingatlanok száma: </strong>
          <span>{data.numOfEstates}</span>
        </div>
        <div>
          <strong>Terület kód: </strong>
          <span>{data.code}</span>
        </div>
        <div>
          <strong>Iroda neve: </strong>
          <span>{data.office?.name}</span>
        </div>
        <div>
          <strong>Cím: </strong>
          <span>{data.office?.address}</span>
        </div>
        <div>
          <strong>Irodavezető: </strong>
          <span>{data.office?.managerName}</span>
        </div>
        <div>
          <strong>Telefonszám: </strong>
          <span>{data.office?.managerPhone}</span>
        </div>
        <div>
          <strong>E-mail cím: </strong>
          <span>{data.office?.managerEmail}</span>
        </div>
        <div>
          <strong>Iroda telefonszám: </strong>
          <span>{data.office?.office_phone}</span>
        </div>
        <div>
          <strong>Iroda e-mail cím: </strong>
          <span>{data.office?.office_email}</span>
        </div>
      </div>
    );
  }

  return (
    <div style={{ fontSize: 16 }}>
      <div>
        <strong>Terület: </strong>
        <span>{renderTextByAreaStatusColor(AreaStatus.FREE, 'Szabad')}</span>
      </div>
      <div>
        <strong>Kezelt ingatlanok száma: </strong>
        <span>{data.numOfEstates}</span>
      </div>
      <div>
        <strong>Terület kód: </strong>
        <span>{data.code}</span>
      </div>
    </div>
  );
};

const getAreaOptions = (status: AreaStatus) => ({
  ...COMMON_POLYGON_OPTIONS,
  ...POLYGON_OPTIONS_BY_STATUS[status]
});

const COMMON_POLYGON_OPTIONS = {
  fillOpacity: 0.4,
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
};

const POLYGON_OPTIONS_BY_STATUS = {
  [AreaStatus.FREE]: {
    fillColor: '#1B7B2B',
    strokeColor: '#1B7B2B'
  },
  [AreaStatus.OPTION]: {
    fillColor: '#FFAD3C',
    strokeColor: '#FFAD3C'
  },
  [AreaStatus.OCCUPIED]: {
    fillColor: '#D1192E',
    strokeColor: '#D1192E'
  },
  [AreaStatus.ADDITIONAL_AREA]: {
    fillColor: '#0c6ddb',
    strokeColor: '#0c6ddb'
  }
};

export default React.memo(Map);
