import styles from './Checkbox.module.scss';

interface CheckboxProps {
  id: string;
  value: boolean;
  label: string;
  setValue: (val: boolean) => void;
}

export const Checkbox = ({ id, value, label, setValue }: CheckboxProps) => {
  return (
    <div className={styles.wrapper}>
      <input
        onChange={(e) => setValue(e.target.checked)}
        type="checkbox"
        name={id}
        id={id}
        checked={value}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
