import styles from './InfoBlock.module.scss';

const InfoBlock = () => {
  return (
    <div className={styles.infoBlock}>
      <div>
        <strong style={{ color: '#000000' }}>Franchisor: Gruppo T.F.M. Szolgáltató Zrt.</strong>
        <br />
        1068 Budapest, Király utca 102.
        <br />
        <a href="mailto:info@tecnocasa.hu">info@tecnocasa.hu</a>
        <br />
        06 1 352 1900
      </div>
      <div>
        <strong style={{ color: '#000000' }}>Reklám és Marketing: MPD Hungary Kft.</strong>
        <br />
        1068 Budapest, Király utca 102
        <br />
        <a href="mailto:info@tecnocasa.hu">info@tecnocasa.hu</a>
      </div>
    </div>
  );
};

export default InfoBlock;
