import { useEffect } from 'react';

import { SnackbarType } from '../../types/enums';

import { ReactComponent as CloseIcon } from './x-mark.svg';

import styles from './Snackbar.module.scss';

interface SnackbarProps {
  type: SnackbarType;
  children: React.ReactNode;
  hide: () => void;
  shouldAutoHide?: boolean;
}

const Snackbar = ({ children, type, shouldAutoHide, hide }: SnackbarProps) => {
  useEffect(() => {
    if (shouldAutoHide) {
      const autoHide = setTimeout(hide, 3000);

      return () => {
        clearTimeout(autoHide);
      };
    }
  }, [children, type]);

  return (
    <div onClick={hide} className={[styles.snackbar, styles[type]].join(' ')}>
      {children}
      <CloseIcon />
    </div>
  );
};

export default Snackbar;
