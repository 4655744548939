import { pagination } from '../../data/labels';
import styles from './Pagination.module.scss';

interface PaginationProps {
  pageCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const Pagination = ({ pageCount, currentPage, setCurrentPage }: PaginationProps) => {
  return (
    <div className={styles.pagination}>
      <div onClick={() => setCurrentPage(0)}>{pagination.start}</div>
      <div onClick={() => setCurrentPage(currentPage === 0 ? 0 : currentPage - 1)}>
        {pagination.previous}
      </div>
      <div className={styles.pageNumbers}>
        {Array(pageCount)
          .fill(undefined)
          .map((_, i) => (
            <div
              key={i}
              className={i === currentPage ? styles.active : ''}
              onClick={() => setCurrentPage(i)}
            >
              {i + 1}
            </div>
          ))}
      </div>
      <div
        onClick={() =>
          setCurrentPage(currentPage === pageCount - 1 ? currentPage : currentPage + 1)
        }
      >
        {pagination.next}
      </div>
      <div onClick={() => setCurrentPage(pageCount - 1)}>{pagination.end}</div>
    </div>
  );
};

export default Pagination;
