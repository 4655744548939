import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import Sidebar from '../../components/Sidebar/Sidebar';
import TableControllers from '../../components/Table/TableControllers';
import { titles, helperLabels, buttonLabels } from '../../data/labels';
import { AppRoute, ButtonType, SnackbarType, SortOption } from '../../types/enums';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import useFilter from '../../hooks/useFilter';
import PaginatedTable from '../../components/Table/PaginatedTable';
import useData from '../../hooks/useData';
import {
  fetchDocumentCategory,
  fetchDocuments,
  fetchDownloadDocument,
  fetchOpenDocument
} from '../../requests';
import { DOCUMENTS_TABLE_COLUMN_CONFIG } from '../../data/tableDefs';
import { DocumentCategory } from '../../types/types';
import Button from '../../components/Button/Button';
import { NotificationContext } from '../../App';
import { isDownloadActionVisible, isViewActionVisible } from '../../utils/common';

const Documents = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [documentList] = useData(
    fetchDocuments,
    'Hiba történt a kategóriák letöltése során, kérjük próbálja később.',
    { categoryId }
  );
  const [category, setCategory] = useState<DocumentCategory>();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredDocuments = useFilter(documentList, query);
  const notify = useContext(NotificationContext);

  const onDownloadFail = useCallback(() => {
    notify('A dokumentum letöltése nem sikerült.', SnackbarType.ERROR);
  }, [notify]);

  const onDownloadSuccess = useCallback(() => {
    notify('A dokumentum letöltése sikeres volt.', SnackbarType.SUCCESS);
  }, [notify]);

  const onOpenFail = useCallback(() => {
    notify('A dokumentum megnyitása nem sikerült.', SnackbarType.ERROR);
  }, [notify]);

  const onOpenSuccess = useCallback(() => {
    notify('A dokumentum megnyitása sikeres volt.', SnackbarType.SUCCESS);
  }, [notify]);

  useEffect(() => {
    if (categoryId) {
      fetchDocumentCategory(Number(categoryId))
        .then((res) => {
          setCategory(res.data.documentCategory);
        })
        .catch(() => navigate(AppRoute.DOCUMENT_CATEGORIES));
    }
  }, [categoryId, navigate]);

  return (
    <>
      <Sidebar title={titles.documents}>
        <></>
      </Sidebar>
      <Layout>
        <h1>
          {titles.documents}/{category?.name}
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <p className="mb-25">
            Ebben a menüpontban lehetősége van központi dokumentumok letöltésére.
          </p>
          <div style={{ marginBottom: '-50px' }}>
            <Button
              type={ButtonType.SECONDARY}
              inverse
              action={() => navigate(AppRoute.DOCUMENT_CATEGORIES)}
            >
              Vissza
            </Button>
          </div>
        </div>
        <TableControllers
          searchTitle={helperLabels.searchInDocuments}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredDocuments}
          columns={[
            DOCUMENTS_TABLE_COLUMN_CONFIG[0],
            {
              field: 'document_category_id',
              headerName: 'Kategória',
              minWidth: 120,
              width: 10,
              isSortable: true,
              renderer: () => <span>{category?.name}</span>
            },
            ...DOCUMENTS_TABLE_COLUMN_CONFIG.slice(3)
          ]}
          actions={[
            {
              title: buttonLabels.download,
              actionHandler: (doc) => {
                fetchDownloadDocument(doc, onDownloadSuccess, onDownloadFail);
              },
              minWidth: 120,
              width: 10,
              visible: isDownloadActionVisible
            },
            {
              title: buttonLabels.view,
              actionHandler: (doc) => {
                fetchOpenDocument(doc, onOpenSuccess, onOpenFail);
              },
              minWidth: 120,
              width: 10,
              visible: isViewActionVisible
            }
          ]}
          defaultSortColumn="created_at"
          defaultSortOrder={SortOption.DESC}
        />
      </Layout>
    </>
  );
};

export default Documents;
