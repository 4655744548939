import { useCallback, useContext, useState } from 'react';

import { NotificationContext, UserContext } from '../App';
import Layout from '../components/Layout/Layout';
import ProfileSidebar from '../components/Sidebar/ProfileSidebar';
import { titles } from '../data/labels';
import Checkbox from '../components/Checkbox/Checkbox';
import { updateUserNotifications } from '../requests';
import { ButtonType, SnackbarType } from '../types/enums';
import Button from '../components/Button/Button';
import { User } from '../types/types';

const MessageSetup = () => {
  const { user, setUser } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [wantsNotifications, setWantsNotifications] = useState(user?.notifiable ?? true);

  const saveSetup = useCallback(() => {
    updateUserNotifications(wantsNotifications)
      .then(() => {
        notify('A beállításokat sikeresen mentettük.', SnackbarType.SUCCESS);
        setUser((it) => ({ ...it, notifiable: wantsNotifications } as User));
      })
      .catch(() => {
        notify('Hiba történt a beállítások módosítása során!', SnackbarType.ERROR);
      });
  }, [wantsNotifications]);

  return (
    <>
      <ProfileSidebar />
      <Layout>
        <h1>{titles.messageSetup}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a az e-mail üzenetek fogadásának beállítására.
        </p>
        <Checkbox
          id="notify"
          label="Szeretnék kapni üzeneteket."
          setValue={setWantsNotifications}
          value={wantsNotifications}
        />
        <Button action={saveSetup} type={ButtonType.SECONDARY}>
          Beállítások mentése
        </Button>
      </Layout>
    </>
  );
};

export default MessageSetup;
