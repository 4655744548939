import { useEffect, useState } from 'react';

import { filterData } from '../utils/common';

function useFilter<T extends { [key in string]: any }>(items: T[], query: string) {
  const [filteredItems, setFilteredItems] = useState<T[]>([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilteredItems(filterData(items, query));
    }, 300);
    return () => clearTimeout(timeout);
  }, [items, query]);

  return filteredItems;
}

export default useFilter;
