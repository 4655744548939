import { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import TableControllers from '../../components/Table/TableControllers';
import { titles, helperLabels } from '../../data/labels';
import { AppRoute, MezzaActivity, SortOption } from '../../types/enums';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../../data/constants';
import useFilter from '../../hooks/useFilter';
import PaginatedTable from '../../components/Table/PaginatedTable';
import { Mezza, MezzaLog, TableColumnConfig } from '../../types/types';
import MezzaDetailOpener from '../../components/MezzaDetailOpener/MezzaDetailOpener';
import { numberComparator } from '../../utils/common';
import useData from '../../hooks/useData';
import { fetchMezzaLogs } from '../../requests';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import MezzaLogFilterBlock from '../../components/MezzaLogFilterBlock/MezzaLogFilterBlock';
import { mezzaActivityTextByActivity } from '../../utils/mezza';

const MezzaLogs = () => {
  const navigate = useNavigate();
  const [logs] = useData(
    fetchMezzaLogs,
    'Hiba történt az Mezzák letöltése során, kérjük próbálja később.'
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredLogs = useFilter(logs, query);
  const [filteredByAdvancedFilters, setFilteredByAdvancedFilters] = useState(filteredLogs);
  const resetFilters = useRef<() => {}>();

  useEffect(() => {
    setFilteredByAdvancedFilters(filteredLogs);
  }, [filteredLogs]);

  const onViewDetails = useCallback(
    ({ mezza_id }: MezzaLog) => {
      navigate(generatePath(AppRoute.ADMIN_MEZZA_DETAILS, { id: mezza_id.toString() }));
    },
    [navigate]
  );

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.mezzaLog}</h1>
        <p className="mb-25">
          Ebben a menüpontban láthatja a mezza rendszerben zajló tevékenységeket.
        </p>
        <TableControllers
          searchTitle={helperLabels.searchInMezzas}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
          resetFilters={resetFilters.current}
          renderFilters={() => (
            <MezzaLogFilterBlock
              logs={filteredLogs}
              onChange={setFilteredByAdvancedFilters}
              resetFilters={resetFilters}
            />
          )}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredByAdvancedFilters}
          columns={MEZZA_LOG_ADMIN_COLUMNS}
          actions={[
            {
              title: 'Tovább',
              actionHandler: onViewDetails,
              minWidth: 60,
              width: 10
            }
          ]}
          defaultSortColumn="created_at"
          defaultSortOrder={SortOption.DESC}
        />
      </Layout>
    </>
  );
};

const MEZZA_LOG_ADMIN_COLUMNS: TableColumnConfig<MezzaLog>[] = [
  {
    field: 'mezza_id',
    headerName: 'Sorszám',
    width: 10,
    minWidth: 100,
    isSortable: true,
    comparator: numberComparator
  },
  {
    field: 'actorOfficeName',
    headerName: 'Iroda',
    width: 15,
    minWidth: 160,
    isSortable: true
  },
  {
    field: 'officeAddress',
    headerName: 'Iroda címe',
    minWidth: 150,
    width: 'auto',
    isSortable: true
  },
  {
    field: 'userName',
    headerName: 'Felhasználó',
    width: 'auto',
    minWidth: 180,
    isSortable: true
  },
  {
    field: 'activity',
    headerName: 'Tevékenység',
    width: 15,
    minWidth: 100,
    isSortable: true,
    renderer: (val) => <>{mezzaActivityTextByActivity[val as MezzaActivity]}</>
  },
  {
    field: 'created_at',
    headerName: 'Dátum',
    width: 15,
    minWidth: 120,
    isSortable: true,
    renderer: (val) => <>{new Date(val).toLocaleDateString()}</>
  },
  {
    field: 'mezza_id',
    headerName: 'Adatlap',
    width: 10,
    minWidth: 60,
    renderer: (_id, log) => <MezzaDetailOpener mezza={{ ...log, id: log.mezza_id } as Mezza} />
  }
];

export default MezzaLogs;
