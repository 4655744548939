import Layout from '../../components/Layout/Layout';
import MezzaSidebar from '../../components/Sidebar/MezzaSidebar';
import { titles } from '../../data/labels';

const Rules = () => {
  return (
    <>
      <MezzaSidebar />
      <Layout>
        <h1>{titles.mezzaSystem}</h1>
        <p className="mb-25">Ebben a menüpontban láthatja a mezza szabályzatot.</p>
        <div style={{ padding: '1.5em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
          <h3>MEZZA FOGALMA</h3>
          <p>
            A fogadó Franchise Partner (ÁTVEVŐ) iroda franchise szerződésében kikötött illetékességi
            területére vonatkozó információ, olyan értékesítés/bérbeadás alatt nem álló, de
            eladásra/kiadásra szánt ingatlanról, amely más Franchise Partner irodánál
            munkaviszonyban álló kolléga munkájából származik és a szabályok szerint
            átadták/átvették.
          </p>
          <p>
            Kifejezetten nem minősül mezzának az információt átadó Franchise Partner (ÁTADÓ) iroda
            kollegája által az ÁTVEVŐ iroda illetékességi területén végzett ricerca/eladó-kiadó
            hirdetés/tábla/molinó/ alapján szerzett információ.
          </p>

          <h3>KÖTELEZŐ INFORMÁCIÓK</h3>
          <ul style={{ fontWeight: 'bold' }}>
            <li>az ingatlan tulajdonosának teljes neve;</li>
            <li>az ingatlan tulajdonosának telefonszáma;</li>
            <li>az ingatlan pontos címe (város, kerület, utca, házszám);</li>
            <li>az Ügyféllel egyeztető kolléga teljes neve (a Megjegyzés rovatban).</li>
          </ul>
          <p style={{ textDecoration: 'underline' }}>
            <strong>
              Amennyiben a fent felsorolt adatok bármelyike hiányzik, az adott információ nem
              minősül mezzának.
            </strong>
          </p>
          <p>
            <strong>
              A mezza átadását előzetes kommunikációnak kell megelőznie az Ügyféllel (tulajdonossal)
              az ÁTADÓ iroda részéről, amelynek lényegét szintén fel kell tüntetni a megjegyzés
              rovatban.
            </strong>
          </p>

          <h3>MEZZA ÉRVÉNYESÉGE</h3>
          <p>
            A mezza a{' '}
            <a type="link" target="_blank" rel="noreferrer" href="https://www.gruppotfm.hu">
              www.gruppotfm.hu
            </a>{' '}
            rendszerben történő átvétele napjától számított 6 hónapig érvényes. Ha ezen időtartamon
            belül megbízási szerződést köt az ÁTVEVŐ iroda a mezzában meghatározott ingatlanra,
            akkor az mezzának minősül. Amennyiben a 6 hónapos időtartamon belül nem jön létre
            szerződéses megbízás a két fél között, úgy az adott ingatlanra a későbbiekben kötött
            megbízás már nem számít mezzának.
          </p>
          <p>
            Ha a 6 hónapos időtartamon belül megbízás születik a mezzából, akkor a mezza
            érvényessége a megbízás fennállása alatt végig fennáll, de legfeljebb a megbízás megírásának dátumától egy évig.
          </p>
          <p>
            Példa a 6 hónapos időtartamra: Átvétel dátuma: 2024.04.05. - Érvényesség dátuma:
            2024.10.04.
          </p>
          <p>Amennyiben egy olyan információ kerül átadásra, mely az átvevő iroda Tecnocloud rendszerében rögzítve van, és az átvevő iroda 3 hónapja nem kereste a tulajdonost, abban az esetben az átadott információ mezzának minősül.</p>

          <h3>MEZZA DÍJA</h3>
          <p>
            Az ÁTADÓ irodát az <strong> ÁTVEVŐ iroda nettó árbevételének a 30 %-a</strong> illeti
            meg.
          </p>
          <p>
            <strong>
              Az ÁTADÓ irodát megillető összeggel az ÁTVEVŐ iroda, a számára kifizetett jutalék
              kifizetését követő 15 napon belül köteles elszámolni.
            </strong>{' '}
            Ez a szabály az Ügyfél részletfizetése esetén is érvényes, ilyenkor az egyes
            részletekhez kapcsolódó arányos részek elszámolására egyenként vonatkozik a 15 napos
            határidő.
          </p>

          <h3>TOVÁBBI SZABÁLYOK</h3>
          <p>
            Mezzának kizárólag az számít, amit írásban a{' '}
            <a type="link" target="_blank" rel="noreferrer" href="https://www.gruppotfm.hu">
              www.gruppotfm.hu
            </a>{' '}
            weboldalon adtak- és vettek át.
          </p>
          <p>
            Az átvett mezza státuszát az ÁTVEVŐ iroda a{' '}
            <a type="link" target="_blank" rel="noreferrer" href="https://www.gruppotfm.hu">
              www.gruppotfm.hu
            </a>{' '}
            oldalon köteles aktualizálni.
          </p>
          <p>
            <strong>
              Mezza átadást telefonos egyeztetésnek kell megelőznie az ÁTADÓ és az ÁTVEVŐ iroda
              irodavezetői között.
            </strong>
          </p>
          <p>
            <strong> Mezzát csak</strong> az adott Franchise Partner irodának az{' '}
            <strong>irodavezetője adhat át és fogadhat</strong>. Mezzát a www.gruppotfm.hu
            weboldalon a területi felelős státuszban lévő kolléga az irodavezető megbízásával
            kezelhet (mezza hozzárendelése).
          </p>
          <p>
            Minden Franchise Partner kizárólag a franchise szerződése szerinti működési területére
            vonatkozóan fogadhat mezzát.
          </p>
          <p>
            Minden olyan mezza címzettje, amely olyan területre esik, amelyre nincs érvényes
            franchise szerződés, a Gruppo T.F.M. Szolgáltató Zrt. Ilyen esetekben a Gruppo T.F.M.
            Szolgáltató Zrt. dönt arról, hogy az adott mezzát melyik Franchise Partner iroda kezeli.
          </p>

          <h3>VITÁS ESETEK</h3>
          <p>
            Ha egy Franchise-partner mezzával kapcsolatban másik Franchise-partner inkorrekt
            magatartását tapasztalja, úgy fel kell vennie vele a kapcsolatot. Ha a vitás kérdésben
            nem jutnak megállapodásra, úgy a GRUPPO T.F.M. Szolgáltató Zrt.-nél tehető bejelentés,
            az esetre vonatkozó összes információval együtt - a vitás fél egyidejű értesítése
            mellett.
          </p>
          <p>
            Mivel ilyen esetekben a Tecnocloud és a{' '}
            <a type="link" target="_blank" rel="noreferrer" href="https://www.gruppotfm.hu">
              www.gruppotfm.hu
            </a>{' '}
            rendszerekben rögzített információk alapvető fontosságúak, az a Franchise-partner, aki a{' '}
            <a type="link" target="_blank" rel="noreferrer" href="https://www.gruppotfm.hu">
              www.gruppotfm.hu
            </a>
            oldalon elmulasztotta rögzíteni a mezza információt, nem tudja a GRUPPO T.F.M.
            Szolgáltató Zrt. előtt érvényesíteni az érdekeit.
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Rules;
