import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';
import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { constructOfficeOptions } from '../../../utils/common';
import { Office as OfficeType } from '../../../types/types';
import { UserContext } from '../../../App';
import { PropertyCategory } from '../../../types/enums';

interface OfficeFieldProps extends MezzaFormFieldProps {
  offices: OfficeType[];
  fromOfficeId?: number;
}

const Office = ({ offices, control, formState, fromOfficeId, resetField }: OfficeFieldProps) => {
  const { user } = useContext(UserContext);
  const [options, setOptions] = useState(
    constructOfficeOptions(offices.filter((it) => ![user?.officeId, fromOfficeId].includes(it.id)))
  );
  const category = useWatch({ control, name: MezzaFormField.CATEGORY });

  useEffect(() => {
    const filteredOffices = offices.filter((it) => ![user?.officeId, fromOfficeId].includes(it.id));

    if (
      category === PropertyCategory.MANAGEMENT &&
      process.env.REACT_APP_PROPERTY_MANAGEMENT_OFFICE_IDS
    ) {
      setOptions(
        constructOfficeOptions(
          filteredOffices.filter((it) =>
            String(process.env.REACT_APP_PROPERTY_MANAGEMENT_OFFICE_IDS)
              .split(',')
              .includes(String(it.id))
          )
        )
      );
      resetField(MezzaFormField.TO_OFFICE_ID);
    } else {
      setOptions(constructOfficeOptions(filteredOffices));
    }
  }, [offices, category]);

  return (
    <Controller
      control={control}
      name={MezzaFormField.TO_OFFICE_ID}
      rules={{ required: 'Kötelező mező!' }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.TO_OFFICE_ID}
          value={
            (
              options.find(({ key }) => key === field.value) ?? {
                value: 'Kérjük válasszon!'
              }
            ).value
          }
          setValue={field.onChange}
          label={inputLabels.recipientOffice}
          options={[{ key: '', value: 'Kérjük válasszon!' }, ...options]}
          withSearch
          error={
            formState.errors[MezzaFormField.TO_OFFICE_ID]?.message
              ? `${formState.errors[MezzaFormField.TO_OFFICE_ID]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(Office);
