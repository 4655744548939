import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import { NotificationContext } from '../../App';
import { titles, buttonLabels, errors, inputLabels, placeholders } from '../../data/labels';
import { createCalendarEvent, updateCalendarEvent } from '../../requests';
import { ButtonType, InputType, SnackbarType, UserRole } from '../../types/enums';
import Popup from '../Popup/Popup';
import {
  USER_ROLE_LABELS,
  checkLength,
  replaceEmptyStringFieldValueWithNull,
  validateItem,
  validateTimeString
} from '../../utils/common';
import { Validation, CalendarCategory, CalendarEvent } from '../../types/types';
import TextArea from '../TextArea/TextArea';
import Input from '../Input/Input';

interface CalendarEventFormProps {
  onExit: (shouldReload?: boolean) => void;
  initialEvent?: CalendarEvent;
}

type CalendarEventFormErrors = {
  name?: string;
  description?: string;
  place?: string;
  min_user_role?: string;
  start_date?: string;
  end_date?: string;
  start?: string;
  end?: string;
};

const CalendarEventForm = ({
  onExit,
  initialEvent: initialEvent = EMPTY_EVENT
}: CalendarEventFormProps) => {
  const { id = '' } = useParams();
  const notify = useContext(NotificationContext);
  const [event, setEvent] = useState(initialEvent);
  const [formErrors, setFormErrors] = useState<CalendarEventFormErrors>(
    {} as CalendarEventFormErrors
  );
  const [endModified, setEndModified] = useState(false);

  const onSuccess = useCallback(() => {
    notify(
      event.id === -1 ? 'Az új esemény sikeresen létrejött.' : 'A esemény frissítése sikeres volt.',
      SnackbarType.SUCCESS
    );
    onExit(true);
  }, [event, notify, onExit]);

  const onError = useCallback(() => {
    notify(
      event.id === -1
        ? 'Az új esemény létehozása nem sikerült.'
        : 'A esemény frissítése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [event, notify]);

  const onSave = useCallback(async () => {
    const errors = validateItem(event, VALIDATIONS);
    setFormErrors(errors as CalendarEventFormErrors);

    if (Object.keys(errors).length === 0 && !isEqual(event, initialEvent)) {
      const handler = event.id !== -1 ? updateCalendarEvent : createCalendarEvent;
      handler(
        replaceEmptyStringFieldValueWithNull({
          ...event,
          calendar_category_id: id
        }),
        onSuccess,
        onError
      );
    }
  }, [event, initialEvent, onError, onSuccess]);

  useEffect(() => {
    if (event.start_date && (!event.end_date || event.start_date > event.end_date || !endModified)) {
      setEvent((it) => ({ ...it, end_date: it.start_date }));
    }
  }, [event]);

  return (
    <Popup
      show
      title={event.id === -1 ? titles.newEvent : titles.editEvent}
      onHide={onExit}
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: onExit,
          inverse: true
        },
        {
          title: buttonLabels.saveData,
          type: ButtonType.PRIMARY,
          action: onSave
        }
      ]}>
      <Input
        id="name"
        label={inputLabels.naming}
        value={event.name ?? ''}
        setValue={(val) =>
          setEvent((it) => ({
            ...it,
            name: val
          }))
        }
        error={formErrors.name}
        placeholder={placeholders.calendarEventName}
      />
      <Input
        id="place"
        label={inputLabels.place}
        value={event.place ?? ''}
        setValue={(val) =>
          setEvent((it) => ({
            ...it,
            place: val
          }))
        }
        placeholder={placeholders.calendarEventPlace}
        error={formErrors.place}
      />
      <Input
        id="min_user_role"
        type={InputType.RADIO}
        value={
          (
            ACCESS_RIGHTS.find(({ key }) => key === event.min_user_role) ?? {
              value: ''
            }
          ).value
        }
        setValue={(val) => setEvent((it) => ({ ...it, min_user_role: val }))}
        label={inputLabels.userRole}
        options={ACCESS_RIGHTS}
        error={formErrors.min_user_role}
      />
      <div className="input-group">
        <label>Kezdés</label>
        <Input
          type={InputType.DATE}
          id="start_date"
          value={event.start_date ?? new Date().toLocaleDateString()}
          setValue={(val) => setEvent((it) => ({ ...it, start_date: val }))}
          error={formErrors.start_date}
          min={new Date().toISOString().split('T')[0]}
        />
        <Input
          type={InputType.TIME}
          id="start"
          value={event.start ?? ''}
          setValue={(val) => setEvent((it) => ({ ...it, start: val }))}
          error={formErrors.start}
        />
      </div>
      <div className="input-group">
        <label>Vége</label>
        <Input
          type={InputType.DATE}
          id="end_date"
          value={event.end_date ?? new Date().toLocaleDateString()}
          setValue={(val) => {
            setEndModified(true);
            setEvent((it) => ({ ...it, end_date: val }));
          }}
          error={formErrors.end_date}
          min={new Date().toISOString().split('T')[0]}
        />
        <Input
          type={InputType.TIME}
          id="end"
          value={event.end ?? ''}
          setValue={(val) => setEvent((it) => ({ ...it, end: val }))}
          error={formErrors.end}
        />
      </div>
      <TextArea
        id="description"
        value={event.description ?? ''}
        error={formErrors.description}
        label={inputLabels.description}
        placeholder={placeholders.calendarEventDescription}
        setValue={(val) => setEvent((it) => ({ ...it, description: val }))}
      />
    </Popup>
  );
};

const ACCESS_RIGHTS = [
  { key: UserRole.USER, value: USER_ROLE_LABELS[UserRole.USER] },
  {
    key: UserRole.AREA_MANAGER,
    value: USER_ROLE_LABELS[UserRole.AREA_MANAGER]
  },
  {
    key: UserRole.OFFICE_MANAGER,
    value: USER_ROLE_LABELS[UserRole.OFFICE_MANAGER]
  }
];

const VALIDATIONS: Validation<CalendarEvent>[] = [
  {
    field: 'name',
    validate: (val: string) => checkLength(val, 2, 80),
    error: `${errors.mandatoryTextField}\r\nMinimum 2, maximum 80 karakter.`
  },
  {
    field: 'description',
    validate: (val: string) => val === '' || val === null || checkLength(val, 2, 200),
    error: `Minimum 2, maximum 200 karakter.`
  },
  {
    field: 'place',
    validate: (val: string) => val === '' || val === null || checkLength(val, 2, 80),
    error: `Minimum 2, maximum 80 karakter.`
  },
  {
    field: 'start_date',
    validate: (val: string, { end_date }) =>
      !isNaN(new Date(val).valueOf()) && (!end_date || val <= end_date),
    error: `Kötelező dátum mező,\r\nnem lehet később mint a vége.`
  },
  {
    field: 'min_user_role',
    validate: (val: string) => ['USER', 'OFFICE_MANAGER', 'AREA_MANAGER'].includes(val),
    error: `Kötelező mező.`
  },
  {
    field: 'start',
    validate: (start: string, { end, start_date, end_date }) => {
      if (!start || !end_date || start_date !== end_date) return true;

      const isValidShape = validateTimeString(start);
      if (!isValidShape) return false;

      if (!end || !validateTimeString(end)) {
        return true;
      }

      if (Number(start.replace(':', '')) > Number(end.replace(':', ''))) {
        return false;
      }

      return true;
    },
    error: `Hibás időpont. Formátum: HH:MM`
  },
  {
    field: 'end',
    validate: (end: string, { start, start_date, end_date }) => {
      if (!end || !end_date || start_date !== end_date) return true;

      const isValidShape = validateTimeString(end);
      if (!isValidShape) return false;

      if (!start || !validateTimeString(start)) {
        return true;
      }

      if (Number(start.replace(':', '')) > Number(end.replace(':', ''))) {
        return false;
      }

      return true;
    },
    error: `Hibás időpont. Formátum: HH:MM`
  }
];

const EMPTY_EVENT: CalendarEvent = {
  id: -1,
  name: '',
  start_date: '',
  end_date: '',
  min_user_role: '',
  calendar_category_id: '',
  calendar_category: {} as CalendarCategory,
  start: '',
  end: '',
  place: '',
  description: ''
};

export default CalendarEventForm;
