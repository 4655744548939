import {
  AgriculturalSubType,
  BusinessSubType,
  CateringSubType,
  DevelopmentSubType,
  FlatSubType,
  GarageSubType,
  HolidaySubType,
  HouseSubType,
  IndustrialSubType,
  MezzaActivity,
  MezzaState,
  OfficeSubType,
  PropertyCategory,
  PropertyType,
  SiteSubType
} from '../types/enums';
import { MapData, Mezza } from '../types/types';

export const mezzaStatusTextByState: Record<MezzaState, string> = {
  [MezzaState.NOT_TAKEN_OVER]: 'Nem átvett',
  [MezzaState.TAKEN_OVER]: 'Átvett',
  [MezzaState.EXPIRED]: 'Lejárt',
  [MezzaState.CLOSED]: 'Lezárt',
  [MezzaState.IN_PROGRESS]: 'Folyamatban',
  [MezzaState.ACQ_WRITTEN]: 'Volt ACQ, megírva',
  [MezzaState.ACQ_NOT_WRITTEN]: 'Volt ACQ, nem lett megírva',
  [MezzaState.NO_ACQ]: 'Nem volt ACQ',
  [MezzaState.DONE]: 'Eladva/Kiadva/Kezelve'
};

export const mezzaActivityTextByActivity: Record<MezzaActivity, string> = {
  [MezzaActivity.SEND]: 'Mezza elküldés',
  [MezzaActivity.ASSIGN]: 'Mezza hozzárendelés',
  [MezzaActivity.STATE_UPDATE]: 'Státusz változtatás',
  [MezzaActivity.MESSAGE]: 'Üzenet írás',
  [MezzaActivity.DATA_UPDATE]: 'Mezza adatlap szerkesztése'
};

export const mezzaCategoryText = {
  [PropertyCategory.RENT]: 'Kiadó',
  [PropertyCategory.SALE]: 'Eladó',
  [PropertyCategory.MANAGEMENT]: 'Ingatlankezelés'
};

export const constructFullAddress = (
  { county_id, district_id, zip, street, street_type, house_number }: Mezza,
  mapData: MapData
) => {
  if (county_id) {
    const county = mapData.counties.find(({ id }) => id === county_id);
    const district = district_id
      ? mapData.districts.find(({ id }) => id === district_id)
      : undefined;

    return `${zip ?? ''} ${county?.name ?? ''}, ${
      district?.name ?? ''
    } ${street} ${street_type} ${house_number}`.trim();
  }

  return '';
};

export const isIncomingMezza = (mezza: Mezza, userId: number, officeId: number) => {
  return mezza.toUser?.id === userId || mezza.recipient_office?.id === officeId;
};

const RENT_OPTIONS = [
  {
    key: '',
    value: 'Kérjük válasszon!'
  },
  { key: PropertyType.FLAT, value: 'Lakás' },
  { key: PropertyType.HOUSE, value: 'Ház' },
  { key: PropertyType.GARAGE, value: 'Garázs' },
  { key: PropertyType.HOLIDAY, value: 'Nyaraló' },
  { key: PropertyType.OFFICE, value: 'Iroda' },
  { key: PropertyType.BUSINESS, value: 'Üzlethelyiség' },
  { key: PropertyType.CATERING, value: 'Vendéglátóipari egység' },
  { key: PropertyType.INDUSTRIAL, value: 'Ipari ingatlan' }
];

export const TYPE_OPTIONS_BY_CATEGORY: Record<PropertyCategory, { key: string; value: string }[]> =
  {
    [PropertyCategory.SALE]: [
      {
        key: '',
        value: 'Kérjük válasszon!'
      },
      { key: PropertyType.FLAT, value: 'Lakás' },
      { key: PropertyType.HOUSE, value: 'Ház' },
      { key: PropertyType.SITE, value: 'Telek' },
      { key: PropertyType.GARAGE, value: 'Garázs' },
      { key: PropertyType.HOLIDAY, value: 'Nyaraló' },
      { key: PropertyType.OFFICE, value: 'Iroda' },
      { key: PropertyType.BUSINESS, value: 'Üzlethelyiség' },
      { key: PropertyType.CATERING, value: 'Vendéglátóipari egység' },
      { key: PropertyType.INDUSTRIAL, value: 'Ipari ingatlan' },
      { key: PropertyType.AGRICULTURAL, value: 'Mezőgazdasági ingatlan' },
      { key: PropertyType.DEVELOPMENT, value: 'Fejlesztési terület' }
    ],
    [PropertyCategory.RENT]: RENT_OPTIONS,
    [PropertyCategory.MANAGEMENT]: RENT_OPTIONS
  };

export const SUBTYPES_BY_TYPE: Record<PropertyType, { key: string; value: string }[]> = {
  [PropertyType.FLAT]: [
    { key: FlatSubType.BRICK, value: 'Tégla' },
    { key: FlatSubType.PANEL, value: 'Panel' },
    { key: FlatSubType.SLIDING_SHUTTER, value: 'Csúsztatott zsalus' }
  ],
  [PropertyType.HOUSE]: [
    { key: HouseSubType.FAMILY, value: 'Családi ház' },
    { key: HouseSubType.TWIN, value: 'Ikerház' },
    { key: HouseSubType.LINE, value: 'Sorház' },
    { key: HouseSubType.PARTIAL, value: 'Házrész' },
    { key: HouseSubType.CASTLE, value: 'Kastély' },
    { key: HouseSubType.FARM, value: 'Tanya' },
    { key: HouseSubType.LIGHT_STRUCTURE, value: 'Könnyűszerkezetes ház' }
  ],
  [PropertyType.SITE]: [
    { key: SiteSubType.RESIDENTIAL_ZONE, value: 'Lakóövezeti' },
    { key: SiteSubType.RESORT_AREA, value: 'Üdülőövezeti' },
    { key: SiteSubType.FOREIGN_TERRITORY, value: 'Külterületi' },
    { key: SiteSubType.OTHER_PLOT, value: 'Egyéb telek' }
  ],
  [PropertyType.GARAGE]: [
    { key: GarageSubType.SINGLE, value: 'Egyedi' },
    { key: GarageSubType.ROOM, value: 'Terem' }
  ],
  [PropertyType.HOLIDAY]: [
    { key: HolidaySubType.WEEKEND_HOME, value: 'Hétvégi házas' },
    { key: HolidaySubType.HOLIDAY_HOUSE, value: 'Üdölőházas' }
  ],
  [PropertyType.OFFICE]: [
    { key: OfficeSubType.OFFICE_BUILDING_A, value: 'Irodaházban A, A+ kat.' },
    { key: OfficeSubType.OFFICE_BUILDING_B, value: 'Irodaházban B, B+ kat.' },
    { key: OfficeSubType.CATEGORY_C, value: 'C kat.' },
    { key: OfficeSubType.FAMILY_HOUSE, value: 'Családi házban' },
    { key: OfficeSubType.FLAT, value: 'Lakásban' },
    { key: OfficeSubType.OTHER_OFFICE, value: 'Egyéb iroda' }
  ],
  [PropertyType.BUSINESS]: [
    { key: BusinessSubType.IN_A_STORE, value: 'Üzletházban' },
    {
      key: BusinessSubType.WITH_BUSINESS_ENTRANCE,
      value: 'Üzleti bejárattal'
    },
    { key: BusinessSubType.IN_THE_YARD, value: 'Udvarban' },
    {
      key: BusinessSubType.OTHER_BUSINESS_PREMISES,
      value: 'Egyéb üzlethelyiség'
    }
  ],
  [PropertyType.CATERING]: [
    { key: CateringSubType.HOTEL, value: 'Szálloda, hotel, panzió' },
    { key: CateringSubType.RESTAURANT, value: 'Étterem, vendéglő' },
    { key: CateringSubType.OTHER, value: 'Egyéb vendéglátó egység' }
  ],
  [PropertyType.INDUSTRIAL]: [
    { key: IndustrialSubType.STORAGE, value: 'Raktár' },
    { key: IndustrialSubType.WORKSHOP, value: 'Műhely' },
    { key: IndustrialSubType.SITE, value: 'Telephely' },
    {
      key: IndustrialSubType.PLOT_FOR_INDUSTRIAL_USE,
      value: 'Telek ipari hasznosításra'
    },
    { key: IndustrialSubType.OTHER, value: 'Egyéb ipari' }
  ],
  [PropertyType.AGRICULTURAL]: [
    {
      key: AgriculturalSubType.GENERAL,
      value: 'Általános mezőgazdasági ingatlan'
    }
  ],
  [PropertyType.DEVELOPMENT]: [
    { key: DevelopmentSubType.RESIDENTIAL, value: 'Lakóterület' },
    {
      key: DevelopmentSubType.COMMERCIAL,
      value: 'Kereskedelmi, szolgáltató terület'
    },
    { key: DevelopmentSubType.MIXED, value: 'Vegyes (lakó és kereskedelmi)' },
    { key: DevelopmentSubType.INDUSTRIAL, value: 'Ipari terület' },
    { key: DevelopmentSubType.RESORT, value: 'Üdülőterület' },
    { key: DevelopmentSubType.SPECIAL, value: 'Különleges terület' }
  ]
};

export const formatMezzas = (mezzas: Mezza[], mapData: MapData) =>
  mezzas.map((it) => {
    const formattedCounty = mapData.counties.find(({ id }) => id === it.county_id)?.name;
    const formattedSettlement = mapData.settlements.find(({ id }) => id === it.settlement_id)?.name;
    const formattedDistrict = it.district_id
      ? mapData.districts.find(({ id }) => id === it.district_id)?.name
      : undefined;

    return {
      ...it,
      formattedCounty,
      formattedSettlement,
      formattedDistrict,
      formattedCategory: mezzaCategoryText[it.category],
      formattedState: mezzaStatusTextByState[it.state],
      formattedType:
        TYPE_OPTIONS_BY_CATEGORY[it.category].find(({ key }) => key === it.type)?.value ?? '',
      formattedSubType:
        SUBTYPES_BY_TYPE[it.type].find(({ key }) => key === it.sub_type)?.value ?? ''
    };
  });
