import { InputHTMLAttributes } from 'react';
import { InputType } from '../../types/enums';
import Dropdown from '../Dropdown/Dropdown';
import RadioInputOptions from '../RadioInputOptions/RadioInputOptions';

import styles from './Input.module.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  type?: InputType;
  setValue?: (val: string) => void;
  label?: string;
  error?: string;
  options?: { key: string; value: string }[];
  withSearch?: boolean;
}

const Input = ({
  type = InputType.TEXT,
  setValue = () => {},
  label,
  error = '',
  options = [],
  withSearch = false,
  ...rest
}: InputProps) => {
  return (
    <div className={error ? styles.error : ''}>
      {label && <label htmlFor={rest.id}>{label}</label>}
      {type === InputType.RADIO && options.length > 0 ? (
        <RadioInputOptions options={options} setValue={setValue} {...rest} />
      ) : type !== InputType.RADIO && options.length > 0 ? (
        <Dropdown
          withSearch={withSearch}
          options={options}
          setValue={setValue}
          disabled={rest.disabled}
          {...rest}
        />
      ) : (
        <input type={type} onChange={(e) => setValue(e.target.value)} name={rest.id} {...rest} />
      )}
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export default Input;
