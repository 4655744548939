import React from 'react';
import { Controller } from 'react-hook-form';

import { MezzaFormField, MezzaFormFieldProps } from '../MezzaForm';
import { inputLabels } from '../../../data/labels';
import Input from '../../Input/Input';

const ClientName = ({ control, formState }: MezzaFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaFormField.CLIENT_NAME}
      rules={{
        required: 'Kötelező mező, minimum 5 karakter!',
        minLength: {
          value: 5,
          message: 'Kötelező mező, minimum 5 karakter!'
        }
      }}
      render={({ field }) => (
        <Input
          id={MezzaFormField.CLIENT_NAME}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.clientName}
          error={
            formState.errors[MezzaFormField.CLIENT_NAME]?.message
              ? `${formState.errors[MezzaFormField.CLIENT_NAME]?.message}`
              : ''
          }
        />
      )}
    />
  );
};

export default React.memo(ClientName);
