import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AreaEditForm from '../components/AreaEditForm/AreaEditForm';
import Button from '../components/Button/Button';
import DeleteForm from '../components/DeleteForm/DeleteForm';
import Layout from '../components/Layout/Layout';
import PropertyForm from '../components/PropertyForm/PropertyForm';
import AdminSidebar from '../components/Sidebar/AdminSidebar';
import PaginatedTable from '../components/Table/PaginatedTable';
import TableControllers from '../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../data/constants';
import { buttonLabels, helperLabels, titles } from '../data/labels';
import { PROPERTY_GROUPS_TABLE_COLUMNS_CONFIG } from '../data/tableDefs';
import useData from '../hooks/useData';
import useFilter from '../hooks/useFilter';
import { fetchArea, fetchDeletePropertyGroup, fetchPropertyGroups } from '../requests';
import { AppRoute, ButtonType } from '../types/enums';
import { Area, PropertyGroup } from '../types/types';

const PropertyGroups = () => {
  const navigate = useNavigate();
  const { areaId } = useParams<{ areaId: string }>();
  const [propertyGroups, refreshPropertyGroups] = useData(
    fetchPropertyGroups,
    'Hiba történt a Területcsoportok letöltése során, kérjük próbálja később.',
    { areaId }
  );
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredPropertyGroups = useFilter(propertyGroups, query);
  const [area, setArea] = useState<Area>();
  const [showPropertyEditor, setShowPropertyEditor] = useState(false);
  const [propertyInEdit, setPropertyInEdit] = useState<PropertyGroup>();
  const [propertyToDelete, setPropertyToDelete] = useState<number>();

  const footerValues = useMemo(() => getFooterValues(propertyGroups), [propertyGroups]);

  const refreshArea = useCallback(() => {
    if (areaId) {
      fetchArea(+areaId)
        .then((res) => {
          setArea(res.data.area);
        })
        .catch(() => navigate(AppRoute.ADMIN_AREAS));
    }
  }, [areaId, navigate]);

  const onCloseEditForm = useCallback(
    (shouldReload = false) => {
      setPropertyInEdit(undefined);
      setShowPropertyEditor(false);

      shouldReload && refreshPropertyGroups();
    },
    [refreshPropertyGroups]
  );

  useEffect(() => {
    refreshArea();
  }, [refreshArea]);

  return (
    <>
      <AdminSidebar />
      <Layout>
        <h1>{titles.editAreas}</h1>
        <p className="mb-25">
          Ebben a menüpontban lehetősége van a területek adatainak szerkesztésére.
        </p>
        {area && <AreaEditForm area={area} refresh={refreshArea} />}
        <TableControllers
          searchTitle={helperLabels.searchInPropertyGroups}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        >
          <div style={{ marginBottom: '0.75rem' }}>
            <Button action={() => setShowPropertyEditor(true)} type={ButtonType.SECONDARY}>
              {buttonLabels.newArea}
            </Button>
          </div>
        </TableControllers>
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredPropertyGroups}
          columns={PROPERTY_GROUPS_TABLE_COLUMNS_CONFIG}
          actions={[
            {
              title: buttonLabels.delete,
              minWidth: 65,
              width: 7,
              actionHandler: (property) => setPropertyToDelete(property.id)
            },
            {
              title: buttonLabels.edit,
              minWidth: 130,
              width: 11,
              actionHandler: (property) => {
                setPropertyInEdit(property);
                setShowPropertyEditor(true);
              }
            }
          ]}
          defaultSortColumn="street"
          footerValues={footerValues}
        />
      </Layout>
      {showPropertyEditor && (
        <PropertyForm
          initialProperty={propertyInEdit}
          onExit={onCloseEditForm}
          areaId={Number(areaId)}
        />
      )}
      {propertyToDelete && (
        <DeleteForm
          onExit={() => setPropertyToDelete(undefined)}
          title={titles.deleteArea}
          subtitle="Biztosan törölni kívánja a területet?"
          onDelete={() => fetchDeletePropertyGroup(propertyToDelete).then(refreshPropertyGroups)}
        />
      )}
    </>
  );
};

const getFooterValues = (propertyGroups: PropertyGroup[]) => {
  const numberOfNormalEstates = propertyGroups.reduce(
    (res, { estates_sum }) => res + estates_sum,
    0
  );

  const numberOfGovtEstates = propertyGroups.reduce(
    (res, { estates_goverment }) => res + estates_goverment,
    0
  );

  const numberOfEstates = numberOfNormalEstates - numberOfGovtEstates;

  return [
    null,
    'Ingatlanok száma összesen (önkormányzati nélkül): ' + numberOfEstates.toString(),
    null,
    null,
    numberOfNormalEstates.toString(),
    numberOfGovtEstates.toString()
  ];
};

export default PropertyGroups;
