import { Mezza } from '../../types/types';
import { CATEGORY_OPTIONS } from '../MezzaForm/inputs/Category';
import { MezzaFormField } from '../MezzaForm/MezzaForm';
import useMapData from '../../hooks/useMapData';
import {
  SUBTYPES_BY_TYPE,
  TYPE_OPTIONS_BY_CATEGORY,
  constructFullAddress,
  mezzaStatusTextByState
} from '../../utils/mezza';
import { PropertyType } from '../../types/enums';

import styles from './MezzaDetails.module.scss';

interface MezzaDetailsProps {
  mezza: Mezza;
  stateOnTop?: boolean;
  renderStateEditorButton?: () => JSX.Element | null;
}

const MezzaDetails = ({
  mezza,
  stateOnTop = false,
  renderStateEditorButton = () => null
}: MezzaDetailsProps) => {
  const mapData = useMapData();

  return (
    <div className={styles.wrapper}>
      {stateOnTop ? (
        <>
          <h3 className="spacer">
            Státusz:
            <span>{mezzaStatusTextByState[mezza.state]}</span>
          </h3>
          {renderStateEditorButton()}
        </>
      ) : null}
      <div>
        Sorszám:
        <span>{mezza.id}</span>
      </div>
      {!stateOnTop ? (
        <h3 className="spacer">
          Státusz:
          <span>{mezzaStatusTextByState[mezza.state]}</span>
        </h3>
      ) : null}
      <div>
        Eladó/Kiadó/Ingatlankezelés:
        <span>
          {CATEGORY_OPTIONS.find(({ key }) => key === mezza[MezzaFormField.CATEGORY])?.value}
        </span>
      </div>
      <div>
        Típus:
        <span>
          {
            TYPE_OPTIONS_BY_CATEGORY[mezza[MezzaFormField.CATEGORY]].find(
              ({ key }) => key === mezza[MezzaFormField.TYPE]
            )?.value
          }
        </span>
      </div>
      <div>
        Altípus:
        <span>
          {
            (SUBTYPES_BY_TYPE[mezza[MezzaFormField.TYPE] as PropertyType] ?? []).find(
              ({ key }) => key === mezza.sub_type
            )?.value
          }
        </span>
      </div>
      <div className="spacer">
        Cím:
        <span>{constructFullAddress(mezza, mapData)}</span>
      </div>
      <div>
        Eladó neve:
        <span>{mezza.client_name}</span>
      </div>
      <div>
        Eladó telefonszáma:
        <span>{mezza.client_phone}</span>
      </div>
      <div className="spacer">
        Eladó e-mail címe:
        <span>{mezza.client_email}</span>
      </div>
      <div>
        Küldő iroda:
        <span>{mezza.sender_office?.name}</span>
      </div>
      <div>
        Név:
        <span>{mezza.fromUser?.name}</span>
      </div>
      <div>
        Telefonszám:
        <span>{mezza.fromUser?.phone_number}</span>
      </div>
      <div className="spacer">
        E-mail:
        <span>{mezza.fromUser?.email}</span>
      </div>
      <div>
        Fogadó iroda:
        <span>{mezza.recipient_office?.name}</span>
      </div>
      <div>
        Név:
        <span>{mezza.toUser?.name}</span>
      </div>
      <div>
        Telefonszám:
        <span>{mezza.toUser?.phone_number}</span>
      </div>
      <div className="spacer">
        E-mail:
        <span>{mezza.toUser?.email}</span>
      </div>
      <div className="spacer">
        Megjegyzés:
        <span>{mezza[MezzaFormField.NOTE]}</span>
      </div>
      <div>
        Létrehozás dátuma:
        <span>{new Date(mezza.created_at).toLocaleDateString()}</span>
      </div>
      <div>
        Utolsó módosítás dátuma:
        <span>{new Date(mezza.updated_at).toLocaleDateString()}</span>
      </div>
    </div>
  );
};

export default MezzaDetails;
