import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormResetField,
  useForm,
  useWatch
} from 'react-hook-form';

import Input from '../Input/Input';
import { inputLabels } from '../../data/labels';
import { CalendarCategory } from '../../types/types';

import styles from './CalendarCategoryFilterBlock.module.scss';

interface CalendarCategoryFilterBlockProps {
  categories: CalendarCategory[];
  onChange: (categories: CalendarCategory[]) => void;
  resetFilters: MutableRefObject<(() => void) | undefined>;
  reApplyAdvancedFilters: MutableRefObject<(() => void) | undefined>;
}

const CalendarCategoryFilterBlock = ({
  categories,
  onChange,
  resetFilters,
  reApplyAdvancedFilters
}: CalendarCategoryFilterBlockProps) => {
  const form = useForm({
    mode: 'onSubmit',
    defaultValues: CALENDAR_CATEGORY_FILTER_BLOCK_DEFAULT_VALUES
  });

  useEffect(() => {
    resetFilters.current = form.reset;
  }, [form.reset]);

  const [name, description]: [string, string] = useWatch({
    name: [CalendarCategoryFilterBlockField.NAME, CalendarCategoryFilterBlockField.DESCRIPTION],
    control: form.control
  });

  const filter = useCallback(() => {
    return [
      { value: name, filterFunc: filterByName },
      { value: description, filterFunc: filterByDescription }
    ].reduce((res, { value, filterFunc }) => {
      if (value) {
        return [...res.filter(filterFunc(value))];
      }

      return res;
    }, categories);
  }, [name, description, categories]);

  useEffect(() => {
    reApplyAdvancedFilters.current = () => onChange(filter());
  }, [onChange, filter]);

  useEffect(() => {
    onChange(filter());
  }, [filter, onChange]);

  return (
    <div className={styles.wrapper}>
      <NameSelector categories={categories} {...form} />
      <PlaceSelector categories={categories} {...form} />
    </div>
  );
};

const filterByName = (name: string) => (event: CalendarCategory) => event.name === name;
const filterByDescription = (description: string) => (event: CalendarCategory) =>
  event.description === description;

export interface CalendarCategoryFilterBlockFieldProps {
  control: Control<CalendarCategoryFilterBlockValues>;
  resetField: UseFormResetField<CalendarCategoryFilterBlockValues>;
  formState: FormState<CalendarCategoryFilterBlockValues>;
  categories: CalendarCategory[];
}

export enum CalendarCategoryFilterBlockField {
  NAME = 'name',
  DESCRIPTION = 'description'
}

const CALENDAR_CATEGORY_FILTER_BLOCK_DEFAULT_VALUES = {
  [CalendarCategoryFilterBlockField.NAME]: '',
  [CalendarCategoryFilterBlockField.DESCRIPTION]: ''
};

export type CalendarCategoryFilterBlockValues = {
  [CalendarCategoryFilterBlockField.NAME]: string;
  [CalendarCategoryFilterBlockField.DESCRIPTION]: string;
};

const DEFAULT_OPTION = { key: '', value: 'Mind' };

const constructNameOptions = (categories: CalendarCategory[]) =>
  categories
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name }) => ({ key: name, value: name }));

const NameSelector = ({ control, categories }: CalendarCategoryFilterBlockFieldProps) => {
  const nameOptions = useMemo(
    () => [DEFAULT_OPTION, ...constructNameOptions(categories)],
    [categories]
  );

  return (
    <Controller
      control={control}
      name={CalendarCategoryFilterBlockField.NAME}
      render={({ field }) => (
        <Input
          id={CalendarCategoryFilterBlockField.NAME}
          value={(nameOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.naming}
          options={nameOptions}
        />
      )}
    />
  );
};

const constructPlaceOptions = (categories: CalendarCategory[]) =>
  categories
    .filter((description) => !!description.description)
    .sort((a, b) => a.description!.localeCompare(b.description!))
    .map(({ description }) => ({ key: String(description), value: description as string }));

const PlaceSelector = ({ control, categories }: CalendarCategoryFilterBlockFieldProps) => {
  const descriptionOptions = useMemo(
    () => [DEFAULT_OPTION, ...constructPlaceOptions(categories)],
    [categories]
  );

  return (
    <Controller
      control={control}
      name={CalendarCategoryFilterBlockField.DESCRIPTION}
      render={({ field }) => (
        <Input
          id={CalendarCategoryFilterBlockField.DESCRIPTION}
          value={
            (descriptionOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value
          }
          setValue={field.onChange}
          label={inputLabels.description}
          options={descriptionOptions}
        />
      )}
    />
  );
};

export default CalendarCategoryFilterBlock;
