import { titles, buttonLabels, inputLabels } from '../../data/labels';
import { ButtonType, UserRole } from '../../types/enums';
import { CalendarCategory, CalendarEvent } from '../../types/types';
import { USER_ROLE_LABELS, formatEventDateTime } from '../../utils/common';
import Input from '../Input/Input';
import Popup from '../Popup/Popup';
import TextArea from '../TextArea/TextArea';

interface CalendarEventDetailsProps {
  event: CalendarEvent;
  category: CalendarCategory;
  onClose: () => void;
  showUserLevel?: boolean;
}

const CalendarEventDetails = ({
  event,
  category,
  onClose,
  showUserLevel = false
}: CalendarEventDetailsProps) => {
  return (
    <Popup
      show
      title={titles.event}
      onHide={onClose}
      footerButtons={[
        {
          title: buttonLabels.close,
          type: ButtonType.PRIMARY,
          action: onClose,
          inverse: true
        }
      ]}
    >
      <div>
        <Input readOnly label={inputLabels.naming} value={event.name} />
        {showUserLevel && (
          <Input
            readOnly
            label={inputLabels.userRole}
            value={USER_ROLE_LABELS[event.min_user_role as UserRole]}
          />
        )}
        <Input readOnly label={inputLabels.place} value={event.place ?? ''} />
        <Input readOnly label={inputLabels.dateTime} value={formatEventDateTime(event)} />
        <Input readOnly label={inputLabels.category} value={category.name} />
        <TextArea readOnly value={event.description ?? ''} label={inputLabels.description} />
      </div>
    </Popup>
  );
};

export default CalendarEventDetails;
